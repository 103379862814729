import React from 'react'
import Innerpage_topbar from './Innerpage_topbar';
import $ from 'jquery';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Creditscore() {
  const navigate = useNavigate();
  var crescr = localStorage.getItem('crdscore');
  var currentaccid = '';

  function setIframeHeightcrd() {
    setTimeout(setHeightcrd, 5000);
  }
  function setHeightcrd() {
    var iframe = document.getElementById('report_iframe');
    var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    var iframeContentHeight = (iframeDocument.body.scrollHeight);
    console.log('hi : ' + iframeContentHeight);
    var newdheight = iframeContentHeight+100;
    console.log(newdheight);
    iframe.style.height = newdheight + 'px';
  }

  function goforloan() {
    var wantedvalue = $('#range-number').text();
    localStorage.setItem('wantedloanvalue', wantedvalue);
    navigate('/loanapplication');
  }


  function exchangevalue(id) {
    var fatchvalue = $('#' + id).val();
    $('#range-number').text(fatchvalue);
  }

  function formatousd() {
    var rangevalue = $('#rancevalue').val();
    var usdvaluetext = formatMoney(rangevalue);
    $('#rancevalue').val(usdvaluetext);
  }

  function formatMoney(value) {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return 'Invalid number';
    }
    const formattedValue = number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formattedValue;
}

function openAccordian(id){
  if(currentaccid!==''){
    $('#'+currentaccid+' .accicon').removeClass('rotateicon')
    $('#'+currentaccid+' .accortext').css('display','none');    
  }
  if(currentaccid!==id){
    $('#'+id+' .accortext').css('display','block');
    $('#'+id+' .accicon').addClass('rotateicon');
  currentaccid = id;
  }else{
    currentaccid = '';
  }
}
  return (
    <div>
      <Innerpage_topbar />
      <div className="main-dash-area column">
        <div className="dash-area">
          <div className="credit-score-area">
            <div className="credit-report">
              <div className="credit-health">
                <div className="main-dash-area column">
                  <iframe id="report_iframe" class="report_iframe" onLoad={setIframeHeightcrd} src="https://app.liquidela.com/crd_score.php" title="Credit report from EFX"></iframe>
                </div>
              </div>
              <div className="apply-loan">
                <div className="loan-title">
                  <div className="report-title">Apply for a loan today</div>
                  <div className="report-title">See below</div>
                </div>
              <div className="loan-title">
                </div>
                <div className="loan-advertiser">
                  <div className="report-title">Advertiser Disclosure</div>
                </div>
                <div className="get-loan-section">
                  <div className="loan">
                    <h3>Loan<br />Amount</h3>
                    <div className="input-box">
                      <input type="text" placeholder="$0.00" id="rancevalue"
                        onChange={() => { exchangevalue('rancevalue') }} onBlur={formatousd} />
                      <p id="range-number" className="coveramount"></p>
                    </div>

                    <p>Minimum of $1000 to Maximum of $100,000</p>

                    <div className="apply-loan">
                      <button type="button" onClick={goforloan}>Apply</button>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-credit-score">
          <div className="credit-main-heading">
            <div className="report-title">About your credit score</div>
          </div>
          <div className='fullwidth' id="tabone">
          <div className="score-tab"  onClick={()=>{openAccordian('tabone')}}>
            <div className="sc-tab-left">What is a good credit score?</div>
            <div className="sc-tab-right accicon"><i className="ri-arrow-right-s-line"></i></div>
          </div>
          <div className='accortext'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          </div>
          </div>

          <div className='fullwidth' id="tabtwo">
          <div className="score-tab"  onClick={()=>{openAccordian('tabtwo')}}>
            <div className="sc-tab-left">What are credit scores used for?</div>
            <div className="sc-tab-right accicon"><i className="ri-arrow-right-s-line"></i></div>
          </div>
          <div className='accortext'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          </div>
          </div>

          <div className='fullwidth' id="tabthree">
          <div className="score-tab"  onClick={()=>{openAccordian('tabthree')}}>
            <div className="sc-tab-left">Why is the score I got from my bank different from your score?</div>
            <div className="sc-tab-right accicon"><i className="ri-arrow-right-s-line"></i></div>
          </div>
          <div className='accortext'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          </div>
          </div>

          <div className='fullwidth' id="tabfour">
          <div className="score-tab"  onClick={()=>{openAccordian('tabfour')}}>
            <div className="sc-tab-left">What is VantageScore 3.0?</div>
            <div className="sc-tab-right accicon"><i className="ri-arrow-right-s-line"></i></div>
          </div>
          <div className='accortext'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          </div>
          </div>

          <div className='fullwidth' id="tabfive">
          <div className="score-tab"  onClick={()=>{openAccordian('tabfive')}}>
            <div className="sc-tab-left">What is Equifax?</div>
            <div className="sc-tab-right accicon"><i className="ri-arrow-right-s-line"></i></div>
          </div>
          <div className='accortext'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}
