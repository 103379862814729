import React from 'react'
import Toppart from './Toppart'
import $ from 'jquery';
import Topsigninbar from './Topsigninbar';
import Checkimg from '../images/check.png';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from './Footer'

export default function Signup_stepone() {
    var sanebillingaddress = "same";
    useEffect(() => {
        $('#billingaddresschk').prop('checked', true);
    })
    var useremail = localStorage.getItem('useremail');
    setTimeout(printval,1000)
       function printval(){
        $('#uemail').val(useremail);
       }
        const navigate = useNavigate();
        function offTheBar(ms) {
            var element = document.getElementById(ms);
            element.classList.add("showemptyfield");
        }
        function openCloseBillinaddress() {
            var chkval = $('#billingaddresschk').prop('checked');
            if(chkval==false){
                sanebillingaddress = "diff";
                $('.billingaddress').css('display','block');
            }else{
                sanebillingaddress = "same";
                $('.billingaddress').css('display','none');
            }
        }

        

        function gofornextstep(){
            var billingaddress = "No value";
            var billingcity = 'No value';
            var billingstate = 'No Value'
            var billingzip = 'No value';
            var fname = $('#fname').val();
            var lname = $('#lname').val();
            var uemail = $('#uemail').val();
            var pass = $('#pass').val();
            var useraddress = $('#useraddress').val();
            var usercity = $('#usercity').val();
            var userstate = $('#userstate').val();
            var userzip = $('#userzip').val();
            if(sanebillingaddress==='diff'){
                billingaddress = $('#billingaddress').val();
                billingcity = $('#billingcity').val();
                billingstate = $('#billingstate').val();
                billingzip = $('#billingzip').val();
            }
            if(fname===''){
                offTheBar('fname')
            }else if(lname===''){
                offTheBar('lname')
            }else if(uemail===''){
                offTheBar('uemail')
            }else if(pass===''){
                offTheBar('pass')
            }else if(useraddress===''){
                offTheBar('useraddress')
            }else if(usercity===''){
                offTheBar('usercity')
            }else if(userstate===''){
                offTheBar('userstate')
            }else if(userzip===''){
                offTheBar('userzip')
            }else if(billingaddress===''){
                offTheBar('billingaddress')
            }else if(billingcity===''){
                offTheBar('billingcity')
            }else if(billingstate===''){
                offTheBar('billingstate')
            }else if(billingzip===''){
                offTheBar('billingzip')
            }else{
               
                var obj = {
                    fname:fname,
                    lname:lname,
                    uemail:uemail,
                    pass:pass,
                    useraddress:useraddress,
                    usercity:usercity,
                    userstate:userstate,
                    userzip:userzip
                }
                console.log('Step one values : = '+obj)
                $.ajax({
                    type: 'POST',
                    url: 'https://app.liquidela.com/signup_stepone.php',
                    data: obj,
                    cache: false,
                    success: function(result) {           
                       if(result!='fail'){   
                       console.log(result);
                       var uname = fname+' '+lname;
                       localStorage.setItem('uid',result); 
                       navigate('/signtwo');
                       }else{
                          alert('Plese check your email.'); 
                       }
                    },
                    });
                }
            }
    
        
  return (
    <div>
        <Toppart />
        <div className="container forms">
    <Topsigninbar/>
    <div className="f-timeline">
        <div className="step">
            <div className="line-t"></div>
            <div className="circle-num">
                <div className="c1 c1color">1</div>
                <div className="c2 c2color">2</div>
                <div className="c3 c2color">3</div>
            </div>
        </div>
        <div className="arrowed-opt">
            <div className="ar-1">Create Your Account</div>
            <div className="ar-2">Billing Information</div>
            <div className="ar-2">Identity Information</div>
        </div>
    </div>
    <div className="checkout-main">
        <div className="checkout-1">
            <div className="leftsection">
                <div className="account-info">
                    <div className="content-box">
                        <div className="group-title"><h4>Account Information</h4></div>
                        <div className="input-form">
                            <input type="text" id="fname" placeholder="First Name *"/>
                            <input type="text" id="lname" placeholder="Last Name *"/>
                        </div>
                        <div className="input-form">
                            <input type="text" placeholder="Email *" id="uemail"/>
                            <input type="password" id="pass" placeholder="Password *"/>
                        </div>
                    </div>    
                </div>
                <div className="account-info">
                    <div className="content-box">
                        <div className="group-title"><h4>Current Address</h4></div>
                        <div className="input-form">
                            <input type="text" id="useraddress" placeholder="Address *"/>
                            <input type="text" id="usercity" placeholder="City *"/>
                        </div>
                        <div className="input-form">
                        <select id="userstate" name="userstate">
                                        <option value="">Select a state</option>
                                        <option value="AL">AL</option>
                                        <option value="AA">AA</option>
                                        <option value="AE">AE</option>
                                        <option value="AK">AK</option>
                                        <option value="AP">AP</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                    </select>
                            <input type="text" id="userzip" placeholder="Zip *"/>
                        </div>
                    </div>    
                </div>

                <div className="account-info">
                    
                    <div className="agreement">
                        <div className="chk"><input type="checkbox" id="billingaddresschk" onChange={openCloseBillinaddress}/></div>
                        <p>I have been at my current address for six months or more.</p>
                    </div>
                    <div className='billingaddress'>
                                <div className="account-info">

                                    <div className="content-box">

                                        <div className="group-title"><h4>New Billing Address</h4></div>

                                        <div className="input-form">
                                            <input type="text" id="billingaddress"  name="billingaddress" placeholder="Address *" />
                                            <input type="text" id="billingcity" name="billingcity" placeholder="City *" />
                                        </div>

                                        <div className="input-form">
                                            <select name="" id="">
                                            <select id="billingstate" name="billingstate">
                                        <option value="">Select a state</option>
                                        <option value="AL">AL</option>
                                        <option value="AA">AA</option>
                                        <option value="AE">AE</option>
                                        <option value="AK">AK</option>
                                        <option value="AP">AP</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                    </select>
                                            </select>
                                            <input type="text" id="billingzip" name="billingzip" placeholder="Zip *" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                <div className="account-info">
                    <div className="agreement">
                        <div className="chk"><input type="checkbox"/></div>
                        <p>By checking this box and clicking ‘next’ you agree to be bound by the Terms of service agreement, privacy policy and to receive important notices and other communications electronically.
                        <br /><br />
                        You are providing express “written” consent for IDIQ, parties calling on behalf of IDIQ, network partner, or any authorized third party on their behalf to call you (including through automated means, e.g., autodialing, text messaging, and pre-recorded messaging) via telephone, mobile device (includes SMS and MMS - charges may apply), and/or email even if your telephone number is listed on any internal, corporate, state, federal, or national Do-Not-Call (DNC) list. This consent is not required as a condition to utilize IDIQ’s services, and you may choose to speak with an individual customer service representative by contacting 877-875-4347.
                        </p>
                    </div>
                </div>
                <div className="account-info">
                    <div className="content-box">
                        <div className="input-form align-content">
                            <button type="button" onClick={gofornextstep}>
                                <span>Next</span>
                                <span className="play-ic"><i className="ri-play-fill"></i></span>
                            </button>
                        </div>
                    </div>    
                </div>
            </div>
            <div className="rightsection">
                <div className="order-summary">
                    <div className="heading"><h3>Order Summary</h3></div>
                    <div className="summary-line"></div>
                    <div className="trial-notice">
                        <div className="txt-trail">
                            <h4>Free Trial</h4>
                        </div>
                        <div className="amount">
                            <p>Free for 30 days then</p>
                            <p><h4>$6.99/mo</h4></p>
                        </div>
                    </div>
                    <div className="features">
                        <div className="txt-trail">
                            <h4>Features</h4>
                        </div>
                        <div className="items">
                            <div className="item">
                                <div className="item-ic"><img src={Checkimg} alt=""/></div>
                                <p>Credit Offers</p>
                            </div>
                            <div className="item">
                                <div className="item-ic"><img src={Checkimg} alt=""/></div>
                                <p>Credit Score</p>
                            </div>
                            <div className="item">
                                <div className="item-ic"><img src={Checkimg} alt=""/></div>
                                <p>Credit Report</p>
                            </div>
                            <div className="item">
                                <div className="item-ic"><img src={Checkimg} alt=""/></div>
                                <p>Identity Restoration</p>
                            </div>
                            <div className="item">
                                <div className="item-ic"><img src={Checkimg} alt=""/></div>
                                <p>Security</p>
                            </div>
                        </div>
                    </div>
                    <div className="total-due">
                        <div className="total-due-txt">
                            <h3>Total Due Today</h3>
                        </div>
                        <div className="amount">
                            <h3>$0.00</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>         
    </div>
    <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>
</div>
      <Footer/>
    </div>
  )
}
