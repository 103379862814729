import React from 'react'
import Toppart from './Toppart'
import Googleimg from '../images/gl.jpg'
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import Topsigninbar from './Topsigninbar';
import Footer from './Footer';

export default function Resetpass() {
    const navigate = useNavigate();
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }
    function sendemailforReset(){
        var uemail = $('#useremail').val();
      
       if(uemail===''){
        alert('Please insert your registered email.');
       }else{
        if(validateEmail(uemail)){
           
            
            var obj = {
                useremail:uemail,
                
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/resetpass.php',
                data: obj,
                cache: false,
                success: function(result) { 
                            
                   if(result.trim()!=="fail"){ 
                        localStorage.setItem('uemail',uemail)  
                        navigate('/passwordresetlinksent');
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
    }
}

function showAlert(msg){
    $('#popalert').css('display','block');
    $('#msgtxt').text(msg);
}

function closepop(){
    $('#popalert').css('display','none');
}
  return (
    <div>
        <Toppart />
            <div className="container forms">

                <Topsigninbar />

                <div className="form login">

                    <div className="form-content">
                        <div className="header">
                            <h1 className='resetpass'>Password Reset</h1>
                            <p className="signuptext text-left">To reset your password, enter the email address you use to sign in</p>
                            
                        </div>

                            <div className="sign-up-txt fullwidthinput">
                                <input type="email" id="useremail" className="usr-txt-bx" placeholder="name@domain.com"/>
                                    <button onClick={sendemailforReset}>Send Reset Link</button>
                            </div>
                    </div>

                </div>

                <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>


            </div> 
            <div className='popupbody' id="popalert">
                <div className='alertbox'>
                    <h3 className='alertheading'>Alert</h3>
                    <p id="msgtxt" className='alertcontent'></p>
                    <div className='alertbuttonholder'>
                        <button onClick={closepop}>Okay</button>
                    </div>
                </div>
            </div>
            <Footer/>
    </div>
  )
}
