import React from 'react'
import Innerpage_topbar from './Innerpage_topbar';
import Footer from './Footer'
import $ from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import { useEffect } from 'react';


export default function Creditreport() {
    const navigate = useNavigate();
    useEffect(()=>{
        const rangeThumb = document.getElementById('range-thumb'),
        rangeNumber = document.getElementById('range-number'),
        rangeNumber2 = document.getElementById('range-number2'),
        rangeLine = document.getElementById('range-line'),
        rangeInput = document.getElementById('range--input')
      
      const rangeInputSlider = () =>{
      //insert the value of the input range
      rangeNumber.textContent = rangeInput.value;
      var usdvaluetext = formatMoney(rangeInput.value);
      rangeNumber2.textContent = usdvaluetext;
      const thumbPosition = (rangeInput.value / rangeInput.max),
            space = rangeInput.offsetWidth - rangeThumb.offsetWidth
      
      rangeThumb.style.left = (thumbPosition * space) + 'px'
      
      rangeLine.style.width = rangeInput.value + '%'
      
      //we call the range input
      rangeInput.addEventListener('input', rangeInputSlider)
      }
      rangeInputSlider();
      function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);
        // Check if the input is a valid number
        if (isNaN(number)) {
            return 'Invalid number';
        }
        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
        return formattedValue;
    }
    })
    function goforLoan(){
        var wantedvalue = $('#range-number').text();
        localStorage.setItem('wantedloanvalue',wantedvalue);
        navigate('/loanapplication');
      }
    function setIframeHeightcrd() {
        setTimeout(setHeightcrd,5000);
       }
       function setHeightcrd(){
           var iframe = document.getElementById('report_iframe'); 
           var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
           var iframeContentHeight = (iframeDocument.body.scrollHeight);
           iframe.style.height = iframeContentHeight + 'px';
       }
    return (
        <div>
            <Innerpage_topbar />
            <section className='topsection'>
            <div className="dash-container">
                <div className="credit-score-area column">
                    <div className="current-personalize row-2">
                        <div className="current-day"><p id="currentdate"></p></div>
                        <div className="credit-r bigfont">Credit Score</div>
                    </div>
                </div> 
            </div>
            </section>``
            <secrtion>
                <div>
                <iframe id="report_iframe" class="report_iframe" onLoad={setIframeHeightcrd}  src="https://app.liquidela.com/crd_report.php" title="Credit report from EFX" ></iframe>
                </div>
                <div className="dash-area-container">
                    <div className="choose-loan column">
                    <div className="range-holder-figures column chooseyourloan">                 
                        <h4>Choose your loan amount</h4>
                    </div>
                    <div className='maketextcenter'><span className='dup_range_value' id="range-number2">$12000</span></div>
                    <div className="range-holder">
                        <div className="range-slider">
                            <div className="range-slider-line" id="range-line"></div>
                        </div>
                        <div className="range--thumb" id="range-thumb">
                            <div className="range_value">
                                <span className="range-value-number nodisplay" id="range-number" ></span>
                            </div>
                        </div>
                        <input type="range" className="range-input" id="range--input" min="1000" max="100000" value="50000" step="1"/>
                        
                    </div>

                    <div className="range-holder-figures row">                 

                        <span>$1,000</span>
                        <span>$100,000+</span>
                        
                    </div>

                    <div className="range-holder-figures column">                 

                        <div className="input-form align-content">
                            <button type="button" onClick={goforLoan}>
                                <span>Continue</span>
                                <span className="play-ic"><i className="ri-play-fill"></i></span>
                            </button>
                        </div>
                    </div>
                    <div className="range-holder-figures row">                 
                        <span>or Talk to a Customer Service Leader</span>
                        <span className='servicefontsizer'>1 888 262 0274
                        </span>
                    </div>
                    </div>
                </div>
            </secrtion>
            <Footer />
        </div>
    )
}
