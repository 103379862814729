import React from 'react'
import Footer from './Footer'
import Innerpage_topbar from './Innerpage_topbar';
import { useEffect } from 'react';
import Editimg from '../images/edit.png'
import Noeditimg from '../images/noedit.png'
import Setting from '../images/settings.png'
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default function User_profile() {

    useEffect(()=>{
        var luid = localStorage.getItem('uid')
        var obj = {
            LUID:luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.liquidela.com/getuserprofile.php',
            data: obj,
            cache: false,
            success: function (result) {
                if (result != 'fail') {
                   var arr = result.split('|');
                   var fname = arr[0];
                   var lname = arr[1];
                   var emailval = arr[2];
                   var mob = arr[3];
                   var address = arr[4];
                   var city = arr[5];
                   var state = arr[6];
                   var zip = arr[7];
                   let firstLetter = fname.charAt(0);
                   let secondletter = lname.charAt(0);
                   let shortname = firstLetter+secondletter
                   $('#userid').text(shortname);
                   $('#input_profile_1').val(fname);
                   changelable('1');
                   $('#input_profile_2').val(lname);
                   changelable('2' );
                   $('#input_profile_3').val(emailval);
                   changelable('3');
                   $('#input_profile_4').val(mob); 
                   changelable('4');                  
                   formatPhoneNumber('input_profile_4','4')                   
                   $('#input_profile_5').val(address);
                   changelable('5');
                   $('#input_profile_6').val(city);
                   changelable('6');
                   $('#input_profile_7').val(state);
                   changelable('7');
                   $('#input_profile_8').val(zip);
                   changelable('8');
                   $('#username').text(fname+' '+lname)

                } else {
                    alert('Plese check your email.');
                }
            },
        });
    })
    function setForEdit(){
        var totalcounter = 9
        for(var i=1; i<totalcounter;i++){
            if(i!==3){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).removeAttr('readonly')
            $('#'+trgid).removeClass('setborderlight')
            }
        }
        $('#submitbtn').css('display','none')
        $('#editbtn').addClass('nodisplay')
        $('#noeditbtn').removeClass('nodisplay')
    }

    function cancleedit(){
        var totalcounter = 9
        for(var i=1; i<totalcounter;i++){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).attr('readonly','true')
            $('#'+trgid).addClass('setborderlight')
        }
        $('#submitbtn').css('display','block');
        $('#editbtn').removeClass('nodisplay');
        $('#noeditbtn').addClass('nodisplay');
    }

    function formatPhoneNumber(inputid,num) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        resetborder(num);
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function updateprofile(){
       
        var luid = localStorage.getItem('uid')
        var first_name = $('#input_profile_1').val();
        var last_name = $('#input_profile_2').val();
        var email = $('#input_profile_3').val();
        var phone_number = $('#input_profile_4').val();
        var address_full = $('#input_profile_5').val();
        var city = $('#input_profile_6').val();
        var state = $('#input_profile_7').val();
        var zip = $('#input_profile_8').val();

        if(first_name===''){
            offTheBar('input_profile_1')
        }
        if(last_name===''){
            offTheBar('input_profile_2')
        }
        if(email===''){
            offTheBar('input_profile_3')
        }
        if(phone_number===''){
            offTheBar('input_profile_4')
        }
        if(address_full===''){
            offTheBar('input_profile_5')
        }
        if(city===''){
            offTheBar('input_profile_6')
        }
        if(state===''){
            offTheBar('input_profile_7')
        }
        if(zip===''){
            offTheBar('input_profile_8')
        }else{
            var obj = {
                first_name:first_name,
                last_name:last_name,
                email:email,
                phone_number:phone_number,
                address_full:address_full,
                city:city,
                state:state,
                zip:zip,
                LUID:luid
            }
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/updateuser.php',
                data: obj,
                cache: false,
                success: function (result) {
                console.log(result);

                    if (result != 'fail') {                       
                        cancleedit();
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }              
    }


    function changelable(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        $('.'+showid).css('display','none');
        $('.'+coverid).css('display','block');
        $('.'+inputid).removeClass('setborderlight');
    }

    function resetborder(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
        $('.'+coverid).css('display','none');
        $('.'+inputid).addClass('setborderlight');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
        }
    }
  return (
    <div>
      <Innerpage_topbar/>
        <div class="main-dash-area column default-h">


<div class="pg-title">
    <p>Profile & Settings</p>
</div>


<div class="main-settings row-4 smallwidth">
    
    

    <div class="profile-area">
        
        <div class="mini-nav  row">
            <div class="profile-tabs">
                <ul class="row-4">
                    <li class="active"><Link to="">Profile Information</Link></li>
                    <li><Link to="/identityInfo">Identity Information</Link></li>
                </ul>
            </div>

            <div class="acc-settings">
                
                <div class="account-txt">
                    <Link to="/accountsettings"><img src={Setting} alt=""/><span class="txt-bold">Account Settings</span></Link>
                </div>
                
            </div>
        </div>
        
        <div class="container-details">
            <div class="account-info">
                    
                <div class="content-box">

                    <div class="group-title ">
                        <h4 className='fullwidth'>Account Information</h4>
                        <div class="edit" onClick={setForEdit} id="editbtn"> <span>Edit</span> <img src={Editimg} alt=""/></div>
                        <div class="edit nodisplay" onClick={cancleedit} id="noeditbtn"> <span>Cancel</span> <img src={Noeditimg} alt=""/></div>
                    </div>

                    <div class="input-form-identity">
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl1'>First Name</label>
                        <label className='showlabelnew showlbl1'>First Name</label>
                        <input className='setborderlight input1' type="text" onClick={()=>{changelable('1')}} onBlur={()=>{resetborder('1')}}  readOnly id="input_profile_1"/>
                        </div>
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl2'>Last Name</label>
                        <label className='showlabelnew showlbl2'>Last Name</label>
                        <input className='setborderlight input2' type="text" onClick={()=>{changelable('2')}} onBlur={()=>{resetborder('2')}}  readOnly id="input_profile_2"/>
                        </div>
                    </div>

                    <div class="input-form-identity">
                       <div className='halfdiv'>
                       <label className='coverlabelnew coverlbl3'>Email</label>
                       <label className='showlabelnew showlbl3'>Email</label>
                        <input className='setborderlight input3' onClick={()=>{changelable('3')}} onBlur={()=>{resetborder('3')}} type="text"  readOnly id="input_profile_3"/>
                        </div>
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl4'>Mobile Number</label>
                        <label className='showlabelnew showlbl4 '>Mobile Number</label>
                        <input className='setborderlight input4' onClick={()=>{changelable('4')}} onBlur={()=>{formatPhoneNumber('input_profile_4','4')}} type="text"  readOnly id="input_profile_4"/>
                        </div>
                    </div>

                </div>    
                
            </div>

            <div class="account-info">
                
                <div class="content-box">

                <div class="group-title ">
                        <h4 className='fullwidth'>Current Address</h4>
                    </div>
                  

                    <div class="input-form-identity">
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl5'>Address</label>
                        <label className='showlabelnew showlbl5'>Address</label>
                        <input className='setborderlight input5'  onClick={()=>{changelable('5')}} onBlur={()=>{resetborder('5')}} type="text" readonly id="input_profile_5"/>
                        </div>
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl6'>City</label>
                        <label className='showlabelnew showlbl6'>City</label>
                        <input className='setborderlight input6' onClick={()=>{changelable('6')}} onBlur={()=>{resetborder('6')}} type="text"  readonly id="input_profile_6"/>
                        </div>
                    </div>

                    <div class="input-form-identity">
                    <div className='halfdiv'>
                        <select className='setborderlight' name="" id="input_profile_7">
                            <option value="">Select a state </option>
                                            <option value="AL">AL</option>
                                            <option value="AA">AA</option>
                                            <option value="AE">AE</option>
                                            <option value="AK">AK</option>
                                            <option value="AP">AP</option>
                                            <option value="AZ">AZ</option>
                                            <option value="AR">AR</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DC">DC</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="GU">GU</option>
                                            <option value="HI">HI</option>
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="IA">IA</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="ME">ME</option>
                                            <option value="MD">MD</option>
                                            <option value="MA">MA</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MS">MS</option>
                                            <option value="MO">MO</option>
                                            <option value="MT">MT</option>
                                            <option value="NE">NE</option>
                                            <option value="NV">NV</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>
                                            <option value="NY">NY</option>
                                            <option value="NC">NC</option>
                                            <option value="ND">ND</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="PR">PR</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VI">VI</option>
                                            <option value="VT">VT</option>
                                            <option value="VA">VA</option>
                                            <option value="WA">WA</option>
                                            <option value="WV">WV</option>
                                            <option value="WI">WI</option>
                                            <option value="WY">WY</option>
                        </select>
                        </div>
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl8'>Zip</label>
                        <label className='showlabelnew showlbl8'>Zip</label>
                        <input className='setborderlight input8' onClick={()=>{changelable('8')}} onBlur={()=>{resetborder('8')}} type="text"  readonly id="input_profile_8"/>
                        </div>
                    </div>

                </div>  
                
                

                <div class="update-btn pos-rel">
                    <div className='coverbutton' id="submitbtn">&nbsp;</div>
                    <button id="updatebtn"  onClick={updateprofile}>Update</button>    
                </div>
                
            </div>

        </div>

        
    </div>

</div>


</div>
      <Footer/>
    </div>
  )
}
