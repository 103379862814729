import React from 'react'
import Toppart from './Toppart'
import $ from 'jquery';
import Topsigninbar from './Topsigninbar';
import Checkimg from '../images/check.png';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from './Footer'

export default function Signup_steptwo() {
    var sanebillingaddress = 'same';
    var monthlycharge = 0.00;

    useEffect(() => {
        $('#billingaddresschk').prop('checked', true);
    })

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }
    function openCloseBillinaddress() {
        var chkval = $('#billingaddresschk').prop('checked');
        if(chkval==false){
            sanebillingaddress = "diff";
            $('.billingaddress').css('display','block');
        }else{
            sanebillingaddress = "same";
            $('.billingaddress').css('display','none');
        }


    }

    function compareDates() {
        var inputYear = parseInt($('#expyear').val());
        var inputvalidyear = 2000+inputYear;
        var inputMonth = parseInt($('#expmonth').val());
        let currentDate = new Date();

        // Extract the current year and month
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
        console.log(currentMonth + ' :' + inputMonth + ' : ' + currentYear + ' : ' + inputYear)

        // Compare the input date's year and month with the current year and month
        if (inputvalidyear < currentYear || (inputvalidyear === currentYear && inputMonth < currentMonth)) {
            showAlert('Date of expiration of your card is invalid.')
        } else if (inputvalidyear > currentYear || (inputvalidyear === currentYear && inputMonth > currentMonth)) {
            saveStepTwoData();
        } else {
            saveStepTwoData();
        }
    }

    function saveStepTwoData(){
        var billingaddress = "No value";
        var billingcity = 'No value';
        var billingstate = 'No Value'
        var billingzip = 'No value';
        
        var cardholdname = $('#cardholdname').val();
        var cardnumber = $('#cardnumber').val();
        var expmonth = $('#expmonth').val();
        var expyear = $('#expyear').val();
        
        var cvv = $('#cvv').val();
        var luid = localStorage.getItem('uid')
        if(sanebillingaddress==='diff'){
            billingaddress = $('#billingaddress').val();
            billingcity = $('#billingcity').val();
            billingstate = $('#billingstate').val();
            billingzip = $('#billingzip').val();
        }
        
        if(cardholdname===''){
            offTheBar('cardholdname')
        }else if(cardnumber===''){
            offTheBar('cardnumber')
        }else if(expmonth===''){
            offTheBar('expmonth')
        }else if(expyear===''){
            offTheBar('expyear')
        }else if(cvv===''){
            offTheBar('cvv')
        }else if(billingaddress===''){
            offTheBar('billingaddress')
        }else if(billingcity===''){
            offTheBar('billingcity')
        }else if(billingstate===''){
            offTheBar('billingstate')
        }else if(billingzip===''){
            offTheBar('billingzip')
        }else{
            var obj = {
                cardholdname:cardholdname,
                cardnumber:cardnumber,
                expmonth:expmonth,
                expyear:expyear,
                sanebillingaddress:sanebillingaddress,
                cvv:cvv,
                LUID:luid,
                monthlycharge:monthlycharge,
                billingaddress:billingaddress,
                billingcity:billingcity,
                billingstate:billingstate,
                billingzip:billingzip
            }
            var alldataarr = [];
            alldataarr.push(obj)
            console.log(alldataarr);
            let arrayString = JSON.stringify(alldataarr);
            localStorage.setItem('dataarray', arrayString);

            window.location.href = 'https://app.liquidela.com/signup_steptwo_token.php';
        }
            
    }

    function showAlert(str) {
        $('.poptopay').css('display', 'block');
        $('#msg').text(str);
    }
    function closepop() {
        $('.poptopay').css('display', 'none');
    }
    return (
        <div>
            <Toppart />
            <div className="container forms">


                <Topsigninbar />

                <div className="f-timeline">

                    <div className="step">
                        <div className="line-t"></div>
                        <div className="circle-num">
                            <div className="c1 c3color">1</div>
                            <div className="c2 c1color">2</div>
                            <div className="c3 c2color">3</div>
                        </div>
                    </div>

                    <div className="arrowed-opt">
                        <div className="ar-3">Create Your Account</div>
                        <div className="ar-1">Billing Information</div>
                        <div className="ar-2">Identity Information</div>
                    </div>

                </div>

                <div className="checkout-main">

                    <div className="checkout-1">

                        <div className="leftsection">

                            <div className="account-info">

                                <div className="content-box">

                                    <div className="group-title"><h4>Billing Information</h4></div>

                                    <div className="input-form">
                                        <div className="card-details">
                                            <label  >Cardholder Full Name</label>
                                            <input type="text" id="cardholdname" name="cardholdname" placeholder="Card Holder FullName" />
                                        </div>

                                        <div className="card-details">
                                            <label  >Credit Card Number</label>
                                            <input type="text" id="cardnumber" name="cardnumber" placeholder="Credit Card Number" />
                                        </div>

                                    </div>



                                </div>

                            </div>

                            <div className="account-info">

                                <div className="content-box">

                                    <div className="group-title"><h4>Credit Card Expiration Date</h4></div>

                                    <div className="input-form credit-card">
                                    <select id="expmonth">
                                            <option value="">MONTH</option>
                                            <option value="01">01 ( JAN )</option>
                                            <option value="02">02 ( FEB )</option>
                                            <option value="03">03 ( MAR )</option>
                                            <option value="04">04 ( APR )</option>
                                            <option value="05">05 ( MAY )</option>
                                            <option value="06">06 ( JUN )</option>
                                            <option value="07">07 ( JUL )</option>
                                            <option value="08">08 ( AUG )</option>
                                            <option value="09">09 ( SEP )</option>
                                            <option value="10">10 ( OCT )</option>
                                            <option value="11">11 ( NOV )</option>
                                            <option value="12">12 ( DEC )</option>
                                        </select>
                                        <select id="expyear"><option value="">YEAR</option><option value="24">2024</option><option value="25">2025</option><option value="26">2026</option><option value="27">2027</option><option value="28">2028</option><option value="29">2029</option><option value="30">2030</option><option value="31">2031</option><option value="32">2032</option><option value="33">2033</option><option value="34">2034</option><option value="35">2035</option><option value="36">2036</option><option value="37">2037</option><option value="38">2038</option><option value="39">2039</option><option value="40">2040</option><option value="41">2041</option><option value="42">2042</option><option value="43">2043</option><option value="44">2044</option><option value="45">2045</option><option value="46">2046</option><option value="47">2047</option><option value="48">2048</option><option value="49">2049</option><option value="50">2050</option><option value="51">2051</option><option value="52">2052</option><option value="53">2053</option><option value="54">2054</option><option value="55">2055</option><option value="56">2056</option><option value="57">2057</option><option value="58">2058</option><option value="59">2059</option><option value="60">2060</option><option value="61">2061</option><option value="62">2062</option><option value="63">2063</option><option value="64">2064</option><option value="65">2065</option><option value="66">2066</option><option value="67">2067</option><option value="68">2068</option><option value="69">2069</option><option value="70">2070</option><option value="71">2071</option><option value="72">2072</option><option value="73">2073</option></select>
                                        <input type="text" id="cvv" name="cvv" placeholder="CVV" />
                                    </div>

                                    

                                </div>

                            </div>

                            

                            <div className="account-info">

                                <div className="agreement">
                                    <div className="chk"><input type="checkbox" id="billingaddresschk" onChange={openCloseBillinaddress} /></div>
                                    <p>My billing address is the same as my current home address</p>
                                </div>


                            </div>

                            <div className='billingaddress'>
                                <div className="account-info">

                                    <div className="content-box">

                                        <div className="group-title"><h4>New Billing Address</h4></div>

                                        <div className="input-form">
                                            <input type="text" id="billingaddress"  name="billingaddress" placeholder="Address *" />
                                            <input type="text" id="billingcity" name="billingcity" placeholder="City *" />
                                        </div>

                                        <div className="input-form">
                                            
                                            <select id="billingstate" name="billingstate">
                                        <option value="">Select a state</option>
                                        <option value="AL">AL</option>
                                        <option value="AA">AA</option>
                                        <option value="AE">AE</option>
                                        <option value="AK">AK</option>
                                        <option value="AP">AP</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                    </select>
                                          
                                            <input type="text" id="billingzip" name="billingzip" placeholder="Zip *" />
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="account-info">

                                <div className="content-box">

                                    <div className="input-form align-content">
                                        <button type="button" onClick={compareDates}>
                                            <span>Next</span>
                                            <span className="play-ic"><i className="ri-play-fill"></i></span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="rightsection">
                            <div className="order-summary">

                                <div className="heading"><h3>Order Summary</h3></div>

                                <div className="summary-line"></div>

                                <div className="trial-notice">
                                    <div className="txt-trail">
                                        <h4>Free Trial</h4>
                                    </div>

                                    <div className="amount">
                                        <p>Free for 30 days then</p>
                                        <p><h4>$6.99/mo</h4></p>
                                    </div>
                                </div>

                                <div className="features">
                                    <div className="txt-trail">
                                        <h4>Features</h4>
                                    </div>

                                    <div className="items">
                                        <div className="item">
                                            <div className="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Offers</p>
                                        </div>

                                        <div className="item">
                                            <div className="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Score</p>
                                        </div>

                                        <div className="item">
                                            <div className="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Report</p>
                                        </div>

                                        <div className="item">
                                            <div className="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Identity Restoration</p>
                                        </div>

                                        <div className="item">
                                            <div className="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Security</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="total-due">
                                    <div className="total-due-txt">
                                        <h3>Total Due Today</h3>
                                    </div>

                                    <div className="amount">
                                        <h3>$0.00</h3>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

                
            </div>
            <section className="poptopay" id="alertpop">
                <div className='shortwhitebase'>
                    <h2>Alert</h2>
                    <p id="msg"></p>
                    <div className='buttonholder'>
                        <button onClick={closepop}>Okay</button>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}
