import React from 'react'
import Toppart from './Toppart'
import Googleimg from '../images/gl.jpg'
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import Topsigninbar from './Topsigninbar';
import Footer from './Footer';


export default function Signup() {
    const navigate = useNavigate();

    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }

      function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function signup(){
      
        var username = $('#username').val();
        var pass = $('#pass').val();
        var cpass = $('#cpass').val();
        
      if(cpass===pass){
        if(username===''){
            offTheBar('username')
        }else if(pass===''){
            offTheBar('pass')
        }else{
            if(validateEmail(username)){
            var obj = {
                uemail:username,
                pass:pass               
            }
            console.log('Step three values : = '+obj)
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/signup.php',
                data: obj,
                cache: false,
                success: function(result) {           
                    if(result.trim()!=="fail"){  
                        console.log(result);
                        localStorage.setItem('uid',result); 
                        localStorage.setItem('uemail', username);
                        navigate('/userdetailsform');
                   }else{
                        alert('Plese check your email.'); 
                    }
                },
                });
            }else{
                alert('Please insert a valid email.');
            }
            }
        }else{
            alert('Password not matcfhing.')
        }
        }



    function gowithgoogle(){
        window.location.href='https://app.liquidela.com/google-oauth.php';
    }
  return (
    <div >
        <Toppart />
      <div>
        <Topsigninbar />

            <div className="form login">
                
                <div className="form-content">
                    <div className="header"><h1>Create your free<br/>Liquidela account</h1></div>

                    
                        
                        <div className="media-options">
                        <button className="field google googlebtn" onClick={gowithgoogle}>
                                    <img src={Googleimg} alt="" className="google-img" />
                                    <span>Continue with Google</span>
                                </button>
                        </div>

                        <div className="line"></div>

                        <div className="field input-field">
                            <input type="text" id="username" className="input" placeholder="name@domain.com"/>
                        </div>

                        <div className="field input-field">
                            <input type="password" id="pass" className="password fullwidth" placeholder="Password" />
                        </div>

                        <div className="field input-field">
                            <input type="password" id="cpass" className="password fullwidth" placeholder="Confirm Password" />
                        </div>

                        <div className="field input-field">
                            <button type="button" onClick={signup}>Create Account</button>
                        </div>

                    



                </div>
                
            </div>

            <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>
        </div>

       

        <Footer/>
    </div>
  )
}
