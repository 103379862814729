import React from 'react'
import Brand_id from '../images/brand-id-dash.png';
import User from '../images/user.png';
import Notification from '../images/notification.png';
import { Link } from 'react-router-dom';

export default function Static_topbar() {

    function showalert(){
        alert('Please complete the profile first')
    }
    return (
        <div >
            <div className="dash-navbar row">

                <div className="navbar-left row">

                    <div className="brand-id">
                        <img src={Brand_id} alt="" />
                    </div>

                    <div className="nav-items">

                        <ul className="row">
                        <li onClick={showalert}>Dashboard</li>
                            <li onClick={showalert}>Identity Restoration</li>
                            <li onClick={showalert}>Get Loan</li>
                            <li onClick={showalert}>Credit Report</li>
                            <li onClick={showalert}>Credit Score</li>
                        </ul>

                    </div>

                </div>

                <div className="navbar-right">

                    <div className="usr-actions row">
                    <div className="notification" onClick={showalert}><img src={Notification} alt="" /></div>
                        <div className="settings row pos-rel settinglink" >
                        <p className='fullwidth'> Profile & Settings</p> <i className="ri-arrow-down-s-line"></i>
                            <div className='dropdownplate'>
                                <Link to="" onClick={showalert}>Profile</Link>
                                <Link to="" onClick={showalert}>Logout</Link>
                            </div>
                        </div>

                        

                    </div>

                </div>

            </div>
        </div>
    )
}
