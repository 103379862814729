import React from 'react'
import Footer from './Footer'
import Innerpage_topbar from './Innerpage_topbar';
import { useEffect } from 'react';
import Editimg from '../images/edit.png'
import Noeditimg from '../images/noedit.png'
import Setting from '../images/settings.png'
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default function Account_settings() {
    function setForEdit(){
        var totalcounter = 9
        for(var i=1; i<totalcounter;i++){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).removeAttr('readonly')
            $('#'+trgid).removeClass('setborderlight')
        }
        $('#updatebtn').removeAttr('disabled')
        $('#editbtn').addClass('nodisplay')
        $('#noeditbtn').removeClass('nodisplay')
    }

    function cancleedit(){
        var totalcounter = 9
        for(var i=1; i<totalcounter;i++){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).attr('readonly','true')
            $('#'+trgid).addClass('setborderlight')
        }
        $('#updatebtn').attr('disabled','true');
        $('#editbtn').removeClass('nodisplay');
        $('#noeditbtn').addClass('nodisplay');
    }

    function formatPhoneNumber(inputid,num) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        resetborder(num);
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function changelable(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        $('.'+showid).css('display','none');
        $('.'+coverid).css('display','block');
        $('.'+inputid).removeClass('setborderlight');
    }

    function resetborder(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
            $('.'+coverid).css('display','none');
            $('.'+inputid).addClass('setborderlight');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
        }
    }

    function savepassword(){
        var luid = localStorage.getItem('uid')
        var password = $('#input_profile_1').val();
        var cpassword = $('#input_profile_5').val();
       
        if(password===''){
            offTheBar('input_profile_1')
        }
        else if(cpassword===''){
            offTheBar('input_profile_5')
        }else if(password!==cpassword){
            alert('Password not matched')
        }else{
            var obj = {
                password:password,
                LUID:luid            
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/updatepassaccount.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result != 'fail') {                       
                        cancleedit();
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }
  return (
    <div>
      <Innerpage_topbar/>
      <div class="main-dash-area column default-h">


<div class="pg-title">
    <p>Account Information</p>
</div>


<div class="main-settings row-4 smallwidth">
    
    

    <div class="profile-area">
        
        <div class="mini-nav  row">
            <div class="profile-tabs">
                <ul class="row-4">
                    <li><Link to="/userprofile">Profile Information</Link></li>
                    
                </ul>
            </div>

            <div class="acc-settings">
                
                
                
            </div>
        </div>
        
        <div class="container-details">
            <div class="account-info">
                    
                <div class="content-box">

                   

                    <div class="input-form-identity">
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl1'>New Password</label>
                        <label className='showlabelnew showlbl1'>New Password</label>
                        <input className='setborderlight input1' type="text" onClick={()=>{changelable('1')}} onBlur={()=>{resetborder('1')}}  readOnly id="input_profile_1"/>
                        </div>
                        <div className='halfdiv'>

                        </div>
                    </div>

                    

                </div>    
                
            </div>

            <div class="account-info">                
                <div class="content-box">
                <div class="group-title ">                        
                    </div>              
                    <div class="input-form-identity">
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl5'>Confirm Password</label>
                        <label className='showlabelnew showlbl5'>Confirm Password</label>
                        <input className='setborderlight input5'  onClick={()=>{changelable('5')}} onBlur={()=>{resetborder('5')}} type="text" readonly id="input_profile_5"/>
                        </div>
                        <div className='halfdiv'>
                        </div>
                    </div>

                    

                </div>  
                
                

                <div class="update-btn">
                    <button id="updatebtn"  onClick={savepassword}>Update</button>    
                </div>
                
            </div>

        </div>

        
    </div>

</div>


</div>
<Footer/>
    </div>
  )
}
