import React from 'react'

export default function Logout() {
    localStorage.setItem('uid', '');
    window.location.href = 'https://app.liquidela.com/logout.php';
  return (
    <div>
      
    </div>
  )
}
