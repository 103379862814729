import React from 'react'
import Innerpage_topbar from './Innerpage_topbar';
import Arrowimg from '../images/apply-arrow.png';
import $ from 'jquery';
import { useEffect } from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Loan_Applicaation() {
    const navigate = useNavigate();
    var preselectedloanamt = localStorage.getItem('wantedloanvalue');
    var oldoption = "opt1";
    var causeofloan = 'Bill Consolidation';
    var oldvhtype = "Own";
    var oldvhid = 'vh1';
    let selectedOption = "Individually";

    useEffect(() => {
        var luid = localStorage.getItem('uid')
        var obj = {
            LUID:luid        

        }
        console.log('Step one values : = '+obj)
        $.ajax({
            type: 'POST',
            url: 'https://app.liquidela.com/check_loan_application.php',
            data: obj,
            cache: false,
            success: function(result) {           
               if(result!='fail'){   
                showAlert('You have already submit an application. Try after few days')               
               }else{
                 
                }
            },
            });


        $('#loanamt').val(preselectedloanamt);
        function updateOption(event) {
            selectedOption = event.target.value;
            if (selectedOption === 'Coapplicant') {
                $('#coapplicant').css('display', 'block');
            } else {
                $('#coapplicant').css('display', 'none');
            }
        }

        // Get all radio buttons with the name 'option'
        let radioButtons = document.querySelectorAll('input[type="radio"]');

        // Add event listeners to each radio button
        radioButtons.forEach(radio => {
            radio.addEventListener('change', updateOption);
        });

        $('#coapplicant').css('display', 'none');
    })

    function offonoptions(ms, causeamt) {
        var element = document.getElementById(oldoption);
        element.classList.remove("active");
        var element = document.getElementById(ms);
        element.classList.add("active");
        causeofloan = causeamt;
        oldoption = ms;
    }

    function changevhicle(type, id) {
        var element = document.getElementById(oldvhid);
        element.classList.remove("active");
        var element = document.getElementById(id);
        element.classList.add("active");
        oldvhtype = type;
        oldvhid = id;
    }
    function offTheBar(ms,topval) {
        $('#'+ms).addClass('showemptyfield')
        $('#'+ms).removeClass('setborderlight');
       
        window.scrollTo({
            top: topval, // 100px from the top
            behavior: 'smooth' // Optional: This adds smooth scrolling
          });
    }

    function submitloanapplication() {
       console.log('hello');
        var coapp_name = 'Not required';
        var co_address = 'Not required';
        var co_email = 'Not required';
        var co_phone = 'Not required';
        var co_dob = 'Not required';
        var co_ssn = 'Not required';
        var co_netincome = 'Not required';
        var co_sourceincome = 'Not required';
        var uname = $('#firstuname').val();
        var lastuname = $('#lastuname').val();
        var loanamt = $('#loanamt').val();
        var uaddress = $('#uaddress').val();
        var userstate = $('#userstate').val();
        var userzip = $('#userzip').val();
        var currentadd = $('#currentadd').val();
        var uemail = $('#uemail').val();
        var phone = $('#phone').val();
        var dob = $('#dob').val();
        var ssn = $('#ssn').val();
        var usercity = $('#usercity').val();
        var netincome = $('#netincome').val();
        var sourceincome = $('#sourceincome').val();
        if (selectedOption === 'Coapplicant') {
            var coapp_name = $('#coapp_name').val();
            var co_address = $('#co_address').val();
            var co_usercity = $('#co_usercity').val();
            var co_userstate = $('#co_userstate').val();
            var co_userzip = $('#co_userzip').val();
            var co_currentadd = $('#co_currentadd').val();
            var co_email = $('#co_email').val();
            var co_phone = $('#co_phone').val();
            var co_dob = $('#co_dob').val();
            var co_ssn = $('#co_ssn').val();
            var co_netincome = $('#co_netincome').val();
            var co_sourceincome = $('#co_sourceincome').val();
        }
        var luid = localStorage.getItem('uid')


        if (uname === '') {
            offTheBar('firstuname',1400)
        }else if (lastuname === '') {
            offTheBar('lastuname',1400)
        }else if (uaddress === '') {
            offTheBar('uaddress',1400)
        }else if (usercity === '') {
            offTheBar('usercity',1400)
        }else if (userstate === '') {
            offTheBar('userstate',1400)
        }else if (userzip === '') {
            offTheBar('userzip',1400)
        }else if (currentadd === '') {
            offTheBar('currentadd',1900)
        }else if (uemail === '') {
            offTheBar('uemail',1900)
        }else if (phone === '') {
            offTheBar('phone',1950)
        }else if (dob === '') {
            offTheBar('dob',1950)
        }else if (ssn === '') {
            offTheBar('ssn',2100)
        }else if (netincome === '') {
            offTheBar('netincome',2150)
        }else if (sourceincome === '') {
            offTheBar('sourceincome',2150)
        }else if (coapp_name === '') {
           offTheBar('coapp_name',2150)
        }else if (co_address === '') {
          offTheBar('co_address',2150)
        }else if (co_usercity === '') {
          offTheBar('co_usercity',2200)
        }else if (co_userstate === '') {
           offTheBar('co_userstate',2200)
        }else if (co_userzip === '') {
           offTheBar('co_userzip',2550)
        }else if (co_currentadd === '') {
           offTheBar('co_currentadd',2550)
        }else if (co_email === '') {
           offTheBar('co_email',2700)
        }else if (co_phone === '') {
          offTheBar('co_phone',2700)
        }else if (co_dob === '') {
           offTheBar('co_dob',2750)
        }else if (co_ssn === '') {
           offTheBar('co_ssn',2850)
        }else if (co_netincome === '') {
          offTheBar('co_netincome',2850)
        }else if (co_sourceincome === '') {
          offTheBar('co_sourceincome',2900)
        } else {
            var obj = {
                uname: uname,
                lastuname:lastuname,
                uaddress:uaddress,
                usercity:usercity,
                userstate:userstate,
                userzip:userzip,
                currentadd:currentadd,
                uemail: uemail,
                phone: phone,
                dob: dob,
                ssn: ssn,
                netincome: netincome,
                sourceincome: sourceincome,
                coapp_name: coapp_name,
                co_address: co_address,
                co_usercity:co_usercity,
                co_userstate:co_userstate,
                co_userzip:co_userzip,
                co_currentadd:co_currentadd,
                co_email: co_email,
                co_phone: co_phone,
                co_dob: co_dob,
                co_ssn: co_ssn,
                co_netincome: co_netincome,
                co_sourceincome: co_sourceincome,
                LUID: luid,
                causeofloan:causeofloan,
                oldvhtype:oldvhtype,
                loanamt:loanamt,
                selectedOption:selectedOption
            }
            console.log(obj)
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/loanapplication.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result != 'fail') {
                        navigate('/congratulation');
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }
    function showAlert(msg) {
        $('#popalert').css('display', 'block');
        $('#msgtxt').text(msg);
    }
    function closepop() {
        $('#popalert').css('display', 'none');
        navigate('/userdashboard');
    }

    function formatssn(id,resetnum){
        var ssntext = $('#'+id).val();
        var finalssn = formatSSN(ssntext);
        resetborder(resetnum);
        $('#'+id).val(finalssn);
        
    }
    function formatSSN(ssn) {
        // Remove any non-digit characters
        ssn = ssn.replace(/\D/g, '');
        
        // Check if the SSN has exactly 9 digits
        if (ssn.length !== 9) {
            alert('SSN must be exactly 9 digits. Please check your SSN');
            return '';
        }
    
        // Format the SSN as XXX-XX-XXXX
        return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }

    function formatPhoneNumber(inputid) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }        
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }


    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return '';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }

    function convertDollarToNumber(dollarStr) {
        // Remove the dollar sign
        let numberStr = dollarStr.replace('$', '');
        // Remove any commas
        numberStr = numberStr.replace(/,/g, '');
        // Convert to float
        let number = parseFloat(numberStr);
        return number;
    }

    function tocurrency(id,resetnum){ 
        var pricetext = $('#'+id).val();
        var coverttonum = convertDollarToNumber(pricetext);
       var priceamt = parseInt(coverttonum);
       var finalprice = formatMoney(priceamt);
       $('#'+id).val(finalprice);
       resetborder(resetnum);
    }

    function changelable(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        $('.'+showid).css('display','none');
        $('.'+coverid).css('display','block');
        $('.'+inputid).removeClass('setborderlight');
    }

    function resetborder(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
            $('.'+coverid).css('display','none');
            $('.'+inputid).addClass('setborderlight');
            $('.'+inputid).removeClass('showemptyfield');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
            $('.'+inputid).removeClass('showemptyfield');
        }
    }

    return (
        <div>
            <Innerpage_topbar />
            <div className="get-loan-ad">
                <div className="banner-txt">
                    <h1>Welcome members of:</h1>
                    <span className="big-heading">Liquidela</span>
                    <div className="summary-txt">
                        <h4>Continue your loan application below</h4>
                        <p>Rates range from 6.99% to 23.99% APR and loan terms range from 24 to 72 months. Only the most creditworthy applicants qualify for the lowest rates, longest loan terms and largest loan amounts. Rates will generally be higher for longer-term loans.</p>
                    </div>
                </div>
            </div>
            <div className="apply-arrow column">
                <h3>Apply Now</h3>
                <img src={Arrowimg} alt="" />
            </div>
            <div className="get-loan-area column">

                <div className="loan-caption">
                    Choose your loan amount
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                    <span className="item-heading">Desired Loan Amount</span>
                </div>

                <div className="loan-f-item align-center brd-bottom desireamt" >
                    <span >$</span>
                    <input type="text" className="txt-box" id="loanamt" placeholder="0.00" />

                </div>

                

                <div className="loan-f-item  mt-30">
                    <span className="item-heading">Loan Purpose</span>
                </div>

                <div className="loan-purpose-grid">
                    <button className="active" id="opt1" onClick={() => { offonoptions('opt1', 'Bill Consolidation') }}>Bill Consolidation</button>
                    <button id="opt2" onClick={() => { offonoptions('opt2', 'Medical Expenses') }}>Medical Expenses</button>
                    <button id="opt3" onClick={() => { offonoptions('opt3', 'Auto Repair') }}>Auto Repair</button>
                    <button id="opt4" onClick={() => { offonoptions('opt4', 'Auto Purchase') }}>Auto Purchase</button>
                    <button id="opt5" onClick={() => { offonoptions('opt5', 'Vacation Expenses') }}>Vacation Expenses</button>
                    <button id="opt6" onClick={() => { offonoptions('opt6', 'Other') }}>Other</button>
                </div>

              

                <div className="vehicle-status">
                    <span className="v-txt pr-10">Do you own a vehicle?</span>
                    <button className="active margin-gap" id="vh1" onClick={() => { changevhicle('Own', 'vh1') }}>Own</button>
                    <button className='margin-gap' id="vh2" onClick={() => { changevhicle('Lease', 'vh2') }}>Lease</button>
                    <button className='margin-gap' id="vh3" onClick={() => { changevhicle('I do not have a vehice', 'vh3') }}>I do not have a vehice</button>
                </div>

            </div>
            <div className="get-loan-individual column">

                <div className="mt-30 loan-f-item setfloatinglabel pos-rel">
                    <span className="item-heading">I will be applying for this loan:</span>
                </div>

                <div className="radio-options row spl-mb-50">
                    <div className="option-1"><input type="radio" checked name="typeapp" value="Individually" /> Individually</div>
                    <div className="option-1"><input type="radio" name="typeapp" value="Coapplicant" /> Co-applicant</div>
                </div>
               <div className='inputcaseholder'>
                <div className="loan-f-item-half pr-15 setfloatinglabel pos-rel">
                    <label className='coverlabel coverlbl1'>First Name</label>
                    <label className='showlabel showlbl1'>First Name</label>
                    <input type="text" id="firstuname" className="txt-box-loan setborderlight input1" onClick={()=>{changelable('1')}} onBlur={()=>{resetborder('1')}} onInput={()=>{resetborder('1')}}/>
                </div>
                <div className="loan-f-item-half pr-15 setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl2'>Last Name</label>
                <label className='showlabel showlbl2'>Last Name</label>
                    <input type="text" id="lastuname" className="txt-box-loan setborderlight input2"  onClick={()=>{changelable('2')}} onBlur={()=>{resetborder('2')}} onInput={()=>{resetborder('2')}}/>

                </div>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl3'>Home Address</label>
                <label className='showlabel showlbl3'>Home Address</label>
                    <input type="text" id="uaddress" className="txt-box-loan input3 setborderlight"  onClick={()=>{changelable('3')}} onBlur={()=>{resetborder('3')}} onInput={()=>{resetborder('3')}}/>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl4'>City</label>
                <label className='showlabel showlbl4'>City</label>
                    <input type="text" id="usercity" className="txt-box-loan input4 setborderlight" onClick={()=>{changelable('4')}} onBlur={()=>{resetborder('4')}} onInput={()=>{resetborder('4')}}/>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl5'>State</label>
                <label className='showlabel showlbl5'>State</label>
                    <input type="text" id="userstate" className="txt-box-loan input5 setborderlight" onClick={()=>{changelable('5')}} onBlur={()=>{resetborder('5')}}  onInput={()=>{resetborder('5')}}/>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl6'>Zip</label>
                <label className='showlabel showlbl6'>Zip</label>
                    <input type="text" id="userzip" className="txt-box-loan input6 setborderlight" onClick={()=>{changelable('6')}} onBlur={()=>{resetborder('6')}} onInput={()=>{resetborder('6')}}/>

                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl7'>Years in current address</label>
                <label className='showlabel showlbl7'>Years in current address</label>
                    <input type="text" id="currentadd" className="txt-box-loan setborderlight input7"  onClick={()=>{changelable('7')}} onBlur={()=>{resetborder('7')}} onInput={()=>{resetborder('7')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl8'>Email Address</label>
                <label className='showlabel showlbl8'>Email Address</label>
                    <input type="text" id="uemail" className="txt-box-loan setborderlight input8" onClick={()=>{changelable('8')}} onBlur={()=>{resetborder('8')}} onInput={()=>{resetborder('8')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl9'>Phone Numbers</label>
                <label className='showlabel showlbl9'>Phone Numbers</label>
                    <input type="text" id="phone" className="txt-box-loan setborderlight input9"  onInput={()=>{formatPhoneNumber('phone')}} onClick={()=>{changelable('9')}} onBlur={()=>{resetborder('9')}} />

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl10'>Date of Birth</label>
                <label className='showlabel showlbl10 solidback'>Date of Birth</label>
                    <input type="date" id="dob" className="txt-box-loan setborderlight input10" onClick={()=>{changelable('10')}} onBlur={()=>{resetborder('10')}} onInput={()=>{resetborder('10')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl11'>Social Security Number</label>
                <label className='showlabel showlbl11 '>Social Security Number</label>
                    <input type="text" id="ssn" className="txt-box-loan setborderlight input11" onClick={()=>{changelable('11')}} onBlur={()=>{formatssn('ssn','11')}} onInput={()=>{resetborder('11')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl12'>Monthly Net Income</label>
                <label className='showlabel showlbl12 '>Monthly Net Income</label>
                    <input type="text" id="netincome" className="txt-box-loan setborderlight input12" onClick={()=>{changelable('12')}} onBlur={()=>{tocurrency('netincome','12')}} onInput={()=>{resetborder('12')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl13'>Source of Income</label>
                <label className='showlabel showlbl13'>Source of Income</label>
                    <input type="text" id="sourceincome" className="txt-box-loan setborderlight input13" onClick={()=>{changelable('13')}} onBlur={()=>{resetborder('13')}}  onInput={()=>{resetborder('13')}}/>
                </div>
            </div>

            <div className="get-loan-individual column coapplicantpart" id="coapplicant">
                <div className="loan-f-item mt-30 "  >
                    <span className="item-heading">Co-applicant</span>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl14'>Co-applicant Name</label>
                <label className='showlabel showlbl14'>Co-applicant Name</label>
                    <input type="text" id="coapp_name" className="txt-box-loan setborderlight input14" onClick={()=>{changelable('14')}} onBlur={()=>{resetborder('14')}} onInput={()=>{resetborder('14')}}/>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl15'>Co-applicant Home Address</label>
                <label className='showlabel showlbl15'>Co-applicant Home Address</label>
                    <input type="text" id="co_address" className="txt-box-loan setborderlight input15" onClick={()=>{changelable('15')}} onBlur={()=>{resetborder('15')}} onInput={()=>{resetborder('15')}}/>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl16'>Co-applicant City</label>
                <label className='showlabel showlbl16'>Co-applicant City</label>
                    <input type="text" id="co_usercity" className="txt-box-loan setborderlight input16" onClick={()=>{changelable('16')}} onBlur={()=>{resetborder('16')}} onInput={()=>{resetborder('16')}}/>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl17'>Co-applicant State</label>
                <label className='showlabel showlbl17'>Co-applicant State</label>
                    <input type="text" id="co_userstate" className="txt-box-loan setborderlight input17" onClick={()=>{changelable('17')}} onBlur={()=>{resetborder('17')}} onInput={()=>{resetborder('17')}}/>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl18'>Co-applicant Zip</label>
                <label className='showlabel showlbl18'>Co-applicant Zip</label>
                    <input type="text" id="co_userzip" className="txt-box-loan setborderlight input18"  onClick={()=>{changelable('18')}} onBlur={()=>{resetborder('18')}} onInput={()=>{resetborder('18')}}/>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl19'>Years in current address</label>
                <label className='showlabel showlbl19'>Years in current address</label>
                    <input type="text" id="co_currentadd" className="txt-box-loan setborderlight input19" onClick={()=>{changelable('19')}} onBlur={()=>{resetborder('19')}} onInput={()=>{resetborder('19')}}/>
                </div>
                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl20'>Co-applicant Email Address</label>
                <label className='showlabel showlbl20'>Co-applicant Email Address</label>
                    <input type="text" id="co_email" className="txt-box-loan setborderlight input20" onClick={()=>{changelable('20')}} onBlur={()=>{resetborder('20')}} onInput={()=>{resetborder('20')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl21'>Co-applicant Phone Numbers</label>
                <label className='showlabel showlbl21'>Co-applicant Phone Numbers</label>
                    <input type="text" id="co_phone" className="txt-box-loan setborderlight input21" onClick={()=>{changelable('21')}} onBlur={()=>{resetborder('21')}} onInput={()=>{formatPhoneNumber('co_phone')}} />
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl22'>Co-applicant Date of Birth</label>
                <label className='showlabel showlbl22 solidback'>Co-applicant Date of Birth</label>
                    <input type="date" id="co_dob" className="txt-box-loan setborderlight input22" onClick={()=>{changelable('22')}} onBlur={()=>{resetborder('22')}} onInput={()=>{resetborder('22')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl23'>Co-applicant Social Security Number</label>
                <label className='showlabel showlbl23'>Co-applicant Social Security Number</label>
                    <input type="text" id="co_ssn" className="txt-box-loan setborderlight input23" placeholder="Co-applicant Social Security Number" onBlur={()=>{formatssn('co_ssn','23')}} onClick={()=>{changelable('23')}} onInput={()=>{resetborder('23')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl24'>Co-applicant Monthly Net Income</label>
                <label className='showlabel showlbl24'>Co-applicant Monthly Net Income</label>
                    <input type="text" id="co_netincome" className="txt-box-loan setborderlight input24"  onBlur={()=>{tocurrency('co_netincome','24')}} onClick={()=>{changelable('24')}} onInput={()=>{resetborder('24')}}/>

                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                <label className='coverlabel coverlbl25'>Co-applicant Source of Income</label>
                <label className='showlabel showlbl25'>Co-applicant Source of Income</label>
                    <input type="text" id="co_sourceincome" className="txt-box-loan setborderlight input25"  onBlur={()=>{resetborder('25')}}  onClick={()=>{changelable('25')}} onInput={()=>{resetborder('25')}}/>

                </div>
            </div>
            
            <div className="agreement column">
                <div className="check">
                    <input type="checkbox" /> By checking this box
                </div>
                <div className="options">
                    <ul>
                        <li>I have reviewed, agree to and acknowledge receipt of the <b><a href="#">Terms of Use, Electronic Consent Agreement, Privacy Notice, Privacy Policy,</a></b> and <b><a href="#">Informational Communications Authorization</a></b>.</li>
                        <li>
                            I confirm that I can receive and access electronic records including the ability to receive email and access PDF files.
                        </li>
                        <li>
                            I authorize Liquidela Inc. to obtain my credit report and use it
                            to review my loan application and see if I qualify for a credit card product from lending partners of Liquidela. I also authorize Liquidela Liquidela Inc. to share my application information with these lending partners to see if I qualify for a credit card.
                        </li>
                        <li>Clicking "Submit Loan Application" may affect your credit score.</li>
                    </ul>
                </div>
                <div className="accept-btn column">
                    <button onClick={submitloanapplication}>Submit Loan Application</button>
                </div>

                <div className="loan-f-item setfloatinglabel pos-rel">
                    <span className="small-txt">
                        <p>If married, you may apply for a loan separately from your spouse.</p>
                        <p>If you are applying for a joint account or an account that you and another person will use, you agree that you both intend to apply for joint credit.</p>

                        <p>If you are applying for individual credit in your own name and are relying on your own income or assets (except community property states, your separate income or assets) and not the income or assets of another person (or community property) for repayment of the credit requested, questions relative to marital status and to income resources and assets of the spouse's need not be answered.However, only the applicant's resources will be evaluated in determining creditworthiness.</p>

                        <p>State lending laws may restrict a lender’s ability to make more than one loan to the same borrower, may limit the total amount that can be loaned to the same borrower at one time, may require us to pay off existing loans in order to provide you with a new loan, or may prevent us from offering you the full loan amount you request.</p>
                        <p>
                            A consumer report may be requested in connection with the processing of your application for credit. Upon request, you will be informed whether or not a consumer report was requested and, if such report was requested, informed of the name and address of the consumer reporting agency that furnished the report. Subsequent consumer reports may be requested or utilized in connection with an update, renewal, or extension of the credit.
                        </p>
                        <p>
                            The Ohio laws against discrimination require that all creditors make credit equally available to all creditworthy customers and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights Commission administers compliance with this law
                        </p>
                    </span>
                </div>
            </div>
            <div className='popupbody' id="popalert">
                <div className='alertbox'>
                    <h3 className='alertheading'>Alert</h3>
                    <p id="msgtxt" className='alertcontent'></p>
                    <div className='alertbuttonholder'>
                        <button onClick={closepop}>Okay</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
