import React from 'react'
import Innerpage_topbar from './Innerpage_topbar';
import { useEffect } from 'react';
import $ from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import Footer from './Footer'
import Brandid from '../images/brand-id.png'



export default function User_dashboard() {
  const navigate = useNavigate();
  var username = localStorage.getItem('uname');
  useEffect(()=>{
    var today = new Date();
    var date = today.getDate();
    var month = (today.getMonth())+1;
    var year = today.getFullYear();
    var todaysdate = month+' / '+date+' / '+year;
    console.log(month);
    $('#currentdate').text('Todays Date  is '+todaysdate)
 
  
  const rangeThumb = document.getElementById('range-thumb'),
  rangeNumber = document.getElementById('range-number'),
  rangeNumber2 = document.getElementById('range-number2'),
  rangeLine = document.getElementById('range-line'),
  rangeInput = document.getElementById('range--input')

const rangeInputSlider = () =>{
//insert the value of the input range
rangeNumber.textContent = rangeInput.value;
var usdvaluetext = formatMoney(rangeInput.value);
rangeNumber2.textContent = usdvaluetext;
//Calculate the position of the input thumb
const thumbPosition = (rangeInput.value / rangeInput.max),
      space = rangeInput.offsetWidth - rangeThumb.offsetWidth

//we insert position of the thumb
rangeThumb.style.left = (thumbPosition * space) + 'px'

//we insert the width to the slider with the value of the input
rangeLine.style.width = rangeInput.value + '%'

//we call the range input
rangeInput.addEventListener('input', rangeInputSlider)
}


rangeInputSlider();

function formatMoney(value) {
    // Convert the string to a number
    const number = parseFloat(value);

    // Check if the input is a valid number
    if (isNaN(number)) {
        return 'Invalid number';
    }

    // Convert the number to a string with commas for thousands separators
    const formattedValue = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    return formattedValue;
}

})
setTimeout(inactiveloader,2500);

function inactiveloader(){
    $('#pageloader').addClass('inactiveloader');
    setTimeout(disableloader,1000);
}

function disableloader(){
    $('#pageloader').addClass('disableloader');
}

function goforLoan(){
  var wantedvalue = $('#range-number').text();
  localStorage.setItem('wantedloanvalue',wantedvalue);
  navigate('/loanapplication');
}
function setIframeHeightcrd() {
    setTimeout(setHeightcrd,5000);
   }
   function setHeightcrd(){
       var iframe = document.getElementById('report_iframe'); 
       
       var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
       var iframeContentHeight = (iframeDocument.body.scrollHeight);
       console.log('hi : '+iframeContentHeight);
       iframe.style.height = iframeContentHeight + 'px';
   }

  return (
    <div>
      <Innerpage_topbar/>
      <div className="main-dash-area column whiteback">
       <div className='bluebase'>
        <div className="greetings">
            <p>Hi {username},</p>
            <p>Good Morning</p>
        </div>

        <div className="dash-area">
            <div className="credit-score-area column">
                
                <div className="current-personalize row-2">
                    <div className="current-day"><p id="currentdate"></p></div>
                    <div className="credit-r"><Link to="/creditreport">Credit Report</Link></div>
                </div>
                
                <div className="credit-score-chart">
                <iframe id="report_iframe" class="report_iframe" onLoad={setIframeHeightcrd}  src="https://app.liquidela.com/onlyscore.php" title="Credit report from EFX" ></iframe>

                </div>
                
                <div className="line-2"></div>

                <div className="txt blctext">
                    <p>Apply for a loan below</p>
                </div>

                
                <div className="choose-loan column">
                    
                    <div className="range-holder-figures column chooseyourloan">                 

                        <h4>Choose your loan amount</h4>
                        
                    </div>
                    <div className='maketextcenter'><span className='dup_range_value' id="range-number2">$12000</span></div>
                    <div className="range-holder">
                        
                        <div className="range-slider">
                            <div className="range-slider-line" id="range-line"></div>
                        </div>

                        <div className="range--thumb" id="range-thumb">
                            <div className="range_value">
                                <span className="range-value-number nodisplay" id="range-number" ></span>
                            </div>
                        </div>

                        
                        <input type="range" className="range-input" id="range--input" min="1000" max="100000" value="50000" step="1"/>
                        


                        
                    </div>

                    <div className="range-holder-figures row">                 

                        <span>$1,000</span>
                        <span>$100,000+</span>
                        
                    </div>

                    <div className="range-holder-figures column">                 

                        <div className="input-form align-content">
                            <button type="button" onClick={goforLoan}>
                                <span>Continue</span>
                                <span className="play-ic"><i className="ri-play-fill"></i></span>
                            </button>
                        </div>
                        
                    </div>

                    <div className="range-holder-figures row">                 

                        <span>or Talk to a Customer Service Leader</span>
                        <span className='servicefontsizer'>1 888 262 0274
                        </span>
                        
                    </div>


                  

                </div>


            </div>
            </div>
        </div>
        <div className='loaderpopup' id="pageloader">
            <div className='loader-whitebase'>
                <div className='loader-logoholder'>
                <img src={Brandid} alt=""/>
                </div>
                <div className='loader-text'>
                    <p>Your account is loading...</p>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}
