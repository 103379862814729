import React from 'react'
import $ from 'jquery';
import Checkimg from '../images/check.png';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from './Footer'
import Static_topbar from './Static_topbar';
import Brandid from '../images/brand-id.png'

export default function User_details_form() {
    const navigate = useNavigate();

    useEffect(() => {
        var useremail = localStorage.getItem('uemail');
        $('#uemail').val(useremail);
        resetborder('11');

    })

    function offTheBar(ms,topval) {
        $('#'+ms).addClass('showemptyfield')
        $('#'+ms).removeClass('setborderlight');

        window.scrollTo({
            top: topval, // 100px from the top
            behavior: 'smooth' // Optional: This adds smooth scrolling
          });
    }


    function savedetails() {
        var fname = $('#fname').val();
        var lname = $('#lname').val();
        var mname = $('#mname').val();
        var ssn = $('#ssn').val();
        var phone = $('#phone').val();
        var dob = $('#dob').val();
        var useraddress = $('#useraddress').val();
        var usercity = $('#usercity').val();
        var userstate = $('#userstate').val();
        var userzip = $('#userzip').val();
        var luid = localStorage.getItem('uid');
        var occupation = $('#occupation').val();
        var uemail = $('#uemail').val();
        var rawphonenum = phone.replace(/[^0-9]/g, "");
        var rawssnum = ssn.replace(/-/g, "");
        if (fname === '') {
            offTheBar('fname',200)
        } else if (lname === '') {
            offTheBar('lname',200)
        } else if (ssn === '') {
            offTheBar('ssn',800)
        } else if (phone === '') {
            offTheBar('phone',800)
        } else if (dob === '') {
            offTheBar('dob',800)
        } else if (useraddress === '') {
            offTheBar('useraddress',550)
        } else if (usercity === '') {
            offTheBar('usercity',550)
        } else if (userstate === '') {
            offTheBar('userstate',550)
        } else if (userzip === '') {
            offTheBar('userzip',550)
        } else if (occupation === '') {
            offTheBar('occupation',250)
        } else if (uemail === '') {
            offTheBar('uemail',800)
        } else {

            var obj = {
                fname: fname,
                lname: lname,
                ssn: ssn,
                phone: phone,
                dob: dob,
                mname: mname,
                useraddress: useraddress,
                usercity: usercity,
                userstate: userstate,
                userzip: userzip,
                occupation: occupation,
                LUID: luid,
                useremail: uemail
            }
            $('#pageloader').removeClass('nodisplay');
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/saveuserdetails.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result != 'fail') {
                        console.log(result);
                        var arr = result.split('|');
                        var checkdetails = arr[0];
                        var userid = arr[1];
                        var useremail = arr[2];
                        var userpass = arr[3];
                        var userphone = arr[4];
                        var efxusername = arr[5];
                        var username = arr[6];
                        localStorage.setItem('uid', userid);
                        localStorage.setItem('upass', userpass);
                        localStorage.setItem('uemail', useremail);
                        localStorage.setItem('userphone', userphone);
                        localStorage.setItem('uname', username);
                        localStorage.setItem('efxuserid', efxusername);
                        window.location.href = "https://app.liquidela.com/crsfunctions.php?LUID=" + luid+"&userphone="+rawphonenum+"&userssn="+rawssnum;
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }
    function formatssn(id,num,hidessn){
        $('#'+id).removeClass('showemptyfield')
        $('#'+id).addClass('setborderlight');
        var ssntext = $('#'+id).val();
        var hdval = $('#'+hidessn).val();
       
      
        
        var finalssn = formatSSNs(ssntext);
        $('#'+id).val(finalssn);
        resetborder(num)
        
    }
    function formatSSNs(ssn) {
        // Remove any non-digit characters
        ssn = ssn.replace(/\D/g, '');
        
        // Check if the SSN has exactly 9 digits
        if (ssn.length !== 9) {
            alert('SSN must be exactly 9 digits. Please check your SSN');
            return '';
        }
    
        // Format the SSN as XXX-XX-XXXX
        return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }
    function formatphone(inputid,num,hidevalue){
        $('#'+inputid).removeClass('showemptyfield')
        $('#'+inputid).addClass('setborderlight');
        var rawphonenumber = $('#'+inputid).val();
        var hdval = $('#'+hidevalue).val();

        $('#'+hidevalue).val(rawphonenumber);
        formatPhoneNumber(inputid,num);
    }
    function formatPhoneNumber(inputid,num) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            resetborder(num)
            return;
        }
        
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value

        inputv.value = formattedPhoneNumber;
        resetborder(num)
    }

    function changelable(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        $('.'+showid).css('display','none');
        $('.'+coverid).css('display','block');
        $('.'+inputid).removeClass('setborderlight');
    }

    function resetborder(idnumber,ms){
        $('#'+ms).removeClass('showemptyfield')
        $('#'+ms).addClass('setborderlight');
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
        $('.'+coverid).css('display','none');
        $('.'+inputid).addClass('setborderlight');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
        }
    }
    function nametheblank(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
        $('.'+coverid).css('display','none');
        $('.'+inputid).addClass('setborderlight');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
        }
    }

    return (
        <div>
            <Static_topbar />
            <div className="container forms">

                <div className="checkout-main nopadding topnomargin">

                    <div className="checkout-1">

                        <div className="leftsection whitebase">

                            <h2 className='headingtext headingcolor'>Let's get to know you better</h2>

                            <div className="account-info">

                                <div className="content-box">

                                    <div className="group-title-small"><h4>Profile</h4></div>

                                    <div className="input-form">
                                    <div className='halfdiv'>
                                    <label className='coverlabelnew coverlbl1'>First Name</label>
                                    <label className='showlabelnew showlbl1'>First Name</label>
                                        <input type="text" className='setborderlight input1' id="fname"  onClick={()=>{changelable('1')}} onChange={()=>{nametheblank('1')}} onBlur={()=>{resetborder('1','fname')}} />
                                    </div>
                                    <div className='halfdiv'>
                                    <label className='coverlabelnew coverlbl2'>Middle Name</label>
                                    <label className='showlabelnew showlbl2'>Middle Name</label>
                                        <input type="text" className='setborderlight input2' id="mname" onClick={()=>{changelable('2')}} onChange={()=>{nametheblank('2')}} onBlur={()=>{resetborder('2','mname')}} />
                                    </div>
                                    </div>

                                    <div className="input-form">
                                        <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl3'>Last Name</label>
                                        <label className='showlabelnew showlbl3'>Last Name</label>
                                        <input type="text" className='setborderlight input3'  onClick={()=>{changelable('3')}} onBlur={()=>{resetborder('3','lname')}} onChange={()=>{nametheblank('3')}} id="lname" />
                                        </div>
                                        <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl4'>Occupation or Job Title</label>
                                        <label className='showlabelnew showlbl4'>Occupation or Job Title</label>
                                            <input type="text" className='setborderlight input4'   id="occupation" onClick={()=>{changelable('4')}} onChange={()=>{nametheblank('4')}} onBlur={()=>{resetborder('4','occupation')}}  />
                                            <p>Example: Designer, Dentist, Retired</p>
                                            </div>
                                    </div>



                                </div>
                            </div>

                            <div className="account-info">

                                <div className="content-box">

                                    <div className="group-title-small"><h4>Address</h4></div>

                                    <div className="input-form">
                                    <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl5'>Address</label>
                                        <label className='showlabelnew showlbl5'>Address</label>
                                        <input type="text" id="useraddress" className='setborderlight input5' onClick={()=>{changelable('5')}} onBlur={()=>{resetborder('5','useraddress')}} onChange={()=>{nametheblank('5')}}/>
                                        </div>
                                        <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl6'>City</label>
                                        <label className='showlabelnew showlbl6'>City</label>
                                        <input type="text" id="usercity" className='setborderlight input6' onClick={()=>{changelable('6')}} onBlur={()=>{resetborder('6','usercity')}} onChange={()=>{nametheblank('6')}}/>
                                        </div>
                                    </div>

                                    <div className="input-form">
                                    <div className='halfdiv'>
                                        <select id="userstate" name="userstate">
                                            <option value="">Select a state </option>
                                            <option value="AL">AL</option>
                                            <option value="AA">AA</option>
                                            <option value="AE">AE</option>
                                            <option value="AK">AK</option>
                                            <option value="AP">AP</option>
                                            <option value="AZ">AZ</option>
                                            <option value="AR">AR</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DC">DC</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="GU">GU</option>
                                            <option value="HI">HI</option>
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="IA">IA</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="ME">ME</option>
                                            <option value="MD">MD</option>
                                            <option value="MA">MA</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MS">MS</option>
                                            <option value="MO">MO</option>
                                            <option value="MT">MT</option>
                                            <option value="NE">NE</option>
                                            <option value="NV">NV</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>
                                            <option value="NY">NY</option>
                                            <option value="NC">NC</option>
                                            <option value="ND">ND</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="PR">PR</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VI">VI</option>
                                            <option value="VT">VT</option>
                                            <option value="VA">VA</option>
                                            <option value="WA">WA</option>
                                            <option value="WV">WV</option>
                                            <option value="WI">WI</option>
                                            <option value="WY">WY</option>
                                        </select>
                                        </div>
                                        <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl7'>Zip</label>
                                        <label className='showlabelnew showlbl7'>Zip</label>
                                        <input type="text" id="userzip" className='setborderlight input7' onClick={()=>{changelable('7')}} onBlur={()=>{resetborder('7','userzip')}} onChange={()=>{nametheblank('7')}}/>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="account-info">

                                <div className="content-box">

                                    <div className="group-title-small"><h4>Identity Information</h4></div>

                                    <div className="input-form">
                                        <div className='halfdiv'>
                                            <label className='coverlabelnew coverlbl8'>Date of Birth</label>
                                            <label className='showlabelnew showlbl8 whiteback bringfront'>Date of Birth</label>
                                            <input type="date" className='setborderlight input8 userdetailsdob'  onClick={()=>{changelable('8')}} onChange={()=>{nametheblank('8')}} onBlur={()=>{resetborder('8','dob')}} id="dob" />
                                        </div>

                                        <div className='halfdiv'>
                                            <label className='coverlabelnew coverlbl9'>Social Security Number</label>
                                            <label className='showlabelnew showlbl9'>Social Security Number</label>
                                            <input type="text" id="ssn"  className='setborderlight input9' onClick={()=>{changelable('9')}} onBlur={()=>{formatssn('ssn','9','hiddenssn')}} onChange={()=>{nametheblank('9')}}/>
                                        </div>
                                        <input type="hidden" id="hiddenssn"/>
                                    </div>

                                    <div className="input-form">
                                    <div className='halfdiv'>
                                        <label className='coverlabelnew coverlbl10'>Phone</label>
                                        <label className='showlabelnew showlbl10'>Phone</label>
                                        <input type="tel" id="phone" className='setborderlight input10' onClick={()=>{changelable('10')}} onBlur={()=>{formatphone('phone','10','hiddenphone')}} onChange={()=>{nametheblank('10')}}/>
                                        <input type="hidden" id="hiddenphone"/>
                                        </div>

                                        <div className='halfdiv'>
                                            <label className='coverlabelnew coverlbl11'>Email</label>
                                            <label className='showlabelnew showlbl11'>Email</label>
                                            <input type="email" id="uemail" className='setborderlight input11' onClick={()=>{changelable('11')}} onBlur={()=>{resetborder('11','uemail')}} onChange={()=>{nametheblank('11')}}/>
                                        </div>
                                    </div>



                                </div>
                            </div>





                            <div className="account-info pt-40">

                                <div className="content-box">

                                    <div className="input-form align-content">
                                        <button type="button" onClick={savedetails}>
                                            <span>Continue</span>
                                            <span className="play-ic"><i className="ri-play-fill"></i></span>
                                        </button>
                                    </div>

                                </div>

                            </div>
                            <div className="account-info pt-40">
                                <p className='diclarationtxt'>By clicking "Continue” and creating an account you accept StitchCredit’s <a href="https://app.termly.io/document/terms-of-service/9423cd69-88eb-4fca-82be-1077f9e84199
" target="_blank">Terms of Use</a> and <a href="https://app.termly.io/document/privacy-policy/95a109ce-0064-415c-92e2-7d9967e723a2
" target="_blank">Privacy Policy</a>. StitchCredit
                                    does not maintain critical personal data, much less sell or otherwise disclose your personal information to anyone else.
                                    You may opt-out of email correspondence, except confirmation Emails, which often contain important information
                                    about your account.
                                </p>
                                <p >By clicking “Continue”, you agree that the phone number and email address you are providing may be used to contact you by Liquidela Inc. (including auto-dialed/auto-selected and prerecorded calls, as well as text/SMS messages). Msg. and data rates apply, and your consent to such contact/marketing is not required for purchase. Msg frequency varies; max 2 texts per day and 7 texts per week. We may also e-mail you and you may let us know at any time if you are no longer interested in hearing from us via a particular communication platform.</p>
                            </div>

                        </div>



                    </div>

                </div>

                <div className='loaderpopup nodisplay' id="pageloader">
                    <div className='loader-whitebase'>
                        <div className='loader-logoholder'>
                            <img src={Brandid} alt="" />
                        </div>
                        <div className='loader-text'>
                            <p>Your account is loading...</p>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </div>
    )
}
