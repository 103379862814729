import React from 'react'
import { Link } from 'react-router-dom'

export default function Topsigninbar() {
  function offTheBar(ms){
    var element = document.getElementById(ms);
      element.classList.add("deactivetopbar");
}
  return (
    <div>
       <div className="pop-up" id="topbar"><span>Already have an account?  <span className="bold whitelink"><Link to="/" >Sign In</Link></span></span> <i className="ri-close-fill close-btn-root" onClick={()=>{offTheBar('topbar')}}></i></div>
    </div>
  )
}
