import React from 'react'
import Footer from './Footer'
import Innerpage_topbar from './Innerpage_topbar';
import { useEffect } from 'react';
import Editimg from '../images/edit.png'
import Noeditimg from '../images/noedit.png'
import Setting from '../images/settings.png'
import NT from '../images/nt.png'
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default function Identity_information() {

    useEffect(()=>{
        var luid = localStorage.getItem('uid')
        var obj = {
            LUID:luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.liquidela.com/getidentity.php',
            data: obj,
            cache: false,
            success: function (result) {
                if (result != 'fail') {
                   var arr = result.split('|');
                   var ssn = arr[0];
                   var dob = arr[1];
                   var mob = arr[2];
                   var fname = arr[3];
                   var lname = arr[4];
                  
                   let firstLetter = fname.charAt(0);
                   let secondletter = lname.charAt(0);
                   let shortname = firstLetter+secondletter
                   $('#userid').text(shortname);
                   $('#input_profile_1').val(ssn);
                   changelable('1')
                   formatssn('input_profile_1')
                   $('#input_profile_2').val(dob);
                   changelable('2')
                   $('#input_profile_3').val(mob);
                   changelable('3')
                   formatPhoneNumber('input_profile_3')
                   
                   $('#username').text(fname+' '+lname)

                } else {
                    alert('Plese check your email.');
                }
            },
        });
    });
    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function setForEdit(){
        var totalcounter = 4
        for(var i=1; i<totalcounter;i++){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).removeAttr('readonly')
            $('#'+trgid).removeClass('setborderlight')
        }
        $('#submitbtn').css('display','none')
        $('#editbtn').addClass('nodisplay')
        $('#noeditbtn').removeClass('nodisplay')
    }

    function cancleedit(){
        var totalcounter = 4
        for(var i=1; i<totalcounter;i++){
            var trgid = 'input_profile_'+i;
            $('#'+trgid).attr('readonly','true')
            $('#'+trgid).addClass('setborderlight')
        }
        $('#submitbtn').css('display','block');
        $('#editbtn').removeClass('nodisplay');
        $('#noeditbtn').addClass('nodisplay');
    }

    function formatPhoneNumber(inputid,num) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        resetborder(num);
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }


    function formatssn(id,num){
        var ssntext = $('#'+id).val();
        var finalssn = formatSSN(ssntext);
        resetborder(num)
        $('#'+id).val(finalssn);
        
    }
    function formatSSN(ssn) {
        // Remove any non-digit characters
        ssn = ssn.replace(/\D/g, '');
        
        // Check if the SSN has exactly 9 digits
        if (ssn.length !== 9) {
            alert('SSN must be exactly 9 digits. Please check your SSN');
            return '';
        }
    
        // Format the SSN as XXX-XX-XXXX
        return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }

    function updateidentity(){
       
        var luid = localStorage.getItem('uid')
        var ssn_number = $('#input_profile_1').val();
        var DOB = $('#input_profile_2').val();
        var phone_number = $('#input_profile_3').val();
       

        if(ssn_number===''){
            offTheBar('input_profile_1')
        }
        else if(DOB===''){
            offTheBar('input_profile_2')
        }
        else if(phone_number===''){
            offTheBar('input_profile_3')
        }
       else{
            var obj = {
                ssn_number:ssn_number,
                DOB:DOB,
                phone_number:phone_number,
                LUID:luid            
            }
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/updateidentity.php',
                data: obj,
                cache: false,
                success: function (result) {
                    console.log(result);
                    if (result != 'fail') {                       
                        cancleedit();
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }


    function changelable(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        $('.'+showid).css('display','none');
        $('.'+coverid).css('display','block');
        $('.'+inputid).removeClass('setborderlight');
    }

    function resetborder(idnumber){
        var showid = 'showlbl'+idnumber;
        var coverid = 'coverlbl'+idnumber;
        var inputid = 'input'+idnumber;
        var chkval = $('.'+inputid).val();
        if(chkval===''){
            $('.'+showid).css('display','block');
        $('.'+coverid).css('display','none');
        $('.'+inputid).addClass('setborderlight');
        }else{
            $('.'+showid).css('display','none');
            $('.'+coverid).css('display','block');
            $('.'+inputid).removeClass('setborderlight');
        }
    }

  return (
    <div>
        <Innerpage_topbar/>
      <div class="main-dash-area column default-h">


<div class="pg-title">
    <p>Identity Information</p>
</div>


<div class="main-settings row-4 smallwidth">
    
   

    <div class="profile-area">
        
        <div class="mini-nav  row">
            <div class="profile-tabs">
                <ul class="row-4">
                    <li><Link to="/userprofile">Profile Information</Link></li>
                    <li class="active"><Link to="">Identity Information</Link></li>
                </ul>
            </div>

            <div class="acc-settings">
                <div class="account-txt">
                <Link to="/accountsettings"><img src={Setting} alt=""/><span class="txt-bold">Account Settings</span></Link>
                </div>
                
            </div>
        </div>
        
        <div class="container-details">


            <div class="account-info">
                    
                <div class="content-box">

                    <div class="group-title ">
                        <h4>Identity Information</h4>
                        <div class="edit" onClick={setForEdit} id="editbtn"> <span>Edit</span> <img src={Editimg} alt=""/></div>
                        <div class="edit nodisplay" onClick={cancleedit} id="noeditbtn"> <span>Cancel</span> <img src={Noeditimg} alt=""/></div>
                    </div>

                    <span class="ssn-note">
                        <p>Your social security number and DOB are used to confirm your identity. This info will be securely transmitted to the credit reporting agencies.</p>
                    </span>

                    


                    <div class="input-form-identity">
                        <div className='halfdiv pb-30'>
                        <label className='coverlabelnew coverlbl1'>Social Security Number</label>
                        <label className='showlabelnew showlbl1'>Social Security Number</label>
                        <input className='setborderlight input1' type="text" onClick={()=>{changelable('1')}} onBlur={()=>{formatssn('input_profile_1','1')}}  readOnly id="input_profile_1"/>
                        </div>
                        <div className='halfdiv'>
                        <label className='coverlabelnew coverlbl2'>Date of Birth</label>
                        <label className='showlabelnew showlbl2 backwhite'></label>
                        <input className='setborderlight input2' type="date" onClick={()=>{changelable('2')}} onBlur={()=>{resetborder('2')}}  readOnly id="input_profile_2"/>
                        </div>
                    </div>  
                
            </div>

            <div class="account-info">
                
                <div class="content-box">

                   

                    <div class="input-form-identity">
                        <div className='halfdiv pb-30'>
                        <label className='coverlabelnew coverlbl3'>Mobile Number</label>
                        <label className='showlabelnew showlbl3'>Mobile Number</label>
                        <input className='setborderlight input3' type="text" onClick={()=>{changelable('3')}} onBlur={()=>{formatPhoneNumber('input_profile_3','3')}}   readOnly id="input_profile_3"/>
                        </div>
                        
                    </div>              

                </div>
                
                <div class="update-btn pos-rel">
                <div className='coverbutton' id="submitbtn">&nbsp;</div>
                    <button id="updatebtn" onClick={updateidentity}>Update</button>    
                </div>

                
            </div>               

        </div>

        
    </div>

</div>
</div>



</div>
<Footer/>
    </div>
  )
}
