import React from 'react'
import Toppart from './Toppart'
import Topbarsignup from './Topbarsignup'
import Googleimg from '../images/gl.jpg'
import Passwordshow from '../images/passwhow.png'
import Passwordhide from '../images/passorg.png'
import {Link, useNavigate} from 'react-router-dom';
import Footer from './Footer'
import $ from 'jquery';

export default function Login() {
    const navigate = useNavigate();
    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function login(){
       
        var username = $('#username').val();
        var pass = $('#pass').val();
        

        if(username===''){
            offTheBar('username')
        }else if(pass===''){
            offTheBar('pass')
        }else{
           
            var obj = {
                username:username,
                pass:pass
               
            }
            console.log('Step three values : = '+obj)
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/login.php',
                data: obj,
                cache: false,
                success: function(result) {           
                    if(result.trim()!=="fail"){  
                   console.log(result);
                   var arr = result.split('|');
                   var checkdetails = arr[0];
                   if(checkdetails==='false'){
                    var userid = arr[1];
                    localStorage.setItem('uid', userid);
                    navigate('/userdetailsform');
                   }else{
                    var userid = arr[1];
                    var useremail = arr[2];
                    var userpass = arr[3];
                    var userphone = arr[4];
                    var efxusername = arr[5];
                    var username = arr[6];
                   localStorage.setItem('uid', userid);
                   localStorage.setItem('upass', userpass);
                   localStorage.setItem('uemail', useremail);
                   localStorage.setItem('userphone', userphone);
                   localStorage.setItem('uname', username);
                   localStorage.setItem('efxuserid', efxusername);
                   navigate('/userdashboard');
                   }
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
            }
        }
        function gowithgoogle(){
            window.location.href='https://app.liquidela.com/google-oauth.php';

        }
    return (
        <div>
            <Toppart />
            <div className="container forms">
                <Topbarsignup/>
                

                <div className="form login">

                    <div className="form-content">
                        <div className="header"><h1>Login</h1></div>

                      

                            <div className="media-options">
                                <button className="field google googlebtn" onClick={gowithgoogle}>
                                    <img src={Googleimg} alt="" className="google-img"/>
                                        <span>Continue with Google</span>
                                </button>
                            </div>

                            <div className="line"></div>

                            <div className="field input-field">
                                <input type="text" id="username" className="input" placeholder="name@domain.com"/>
                            </div>

                            <div className="field input-field">
                                <input type="password" id="pass" className="password" placeholder="Password" />
                            </div>

                            <div className="field input-field">
                                <button onClick={login}>Sign In</button>
                            </div>

                            <div className="field input-field">
                                <p>Forgot password? <span className="bold"><Link to="/resetpassword" >Click<span> here</span></Link> </span></p>
                            </div>

                        



                    </div>

                </div>

                <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>

        </div>
    )
}
