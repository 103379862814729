import React from 'react'
import Footer from './Footer'
import Innerpage_topbar from './Innerpage_topbar';
import Restorebg1 from '../images/restoreid-bg-1.png'
import Restorebg2 from '../images/restoreid-bg-2.png'
import { Link, useNavigate } from 'react-router-dom';

export default function Identity_restoration() {
    const navigate = useNavigate();
    function gofordash() {
        navigate('/userdashboard');
    }
    return (
        <div>
            <Innerpage_topbar />
            <div class="container">

                <div class="pop-container">

                    <div class="parttern-1"><img src={Restorebg1} alt="" /></div>
                    <div class="parttern-2"><img src={Restorebg2} alt="" /></div>

                    <div class="payment-form align-item-center">
                        <div class="content">

                            <h1>Equifax® ID Restoration</h1>

                            <h3>Has your identity been compromised?</h3>

                            <ul>
                                <li>A specialist will help you work through the process.</li>
                                <li>Once your issues are resolved, we continue to monitor the situation for three months.</li>
                                <li>Call today so that we may help you verify and begin resolving any discrepancies.</li>
                            </ul>

                            <div class="short-info">
                                <h3>Call 1-877-368-4940 to get started</h3>
                            </div>

                            <div class="go-back-btn">
                                <button class="back-btn" onClick={gofordash}>Return to Dashboard</button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}
