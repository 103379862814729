import React from 'react'
import Toppart from './Toppart'
import $ from 'jquery';
import Topsigninbar from './Topsigninbar';
import Checkimg from '../images/check.png';
import { Link, useNavigate } from 'react-router-dom';
import Ntimg from '../images/nt.png'
import { useEffect } from 'react';
import Footer from './Footer'


export default function Signup_stepthree() {
    const navigate = useNavigate();
    function shownotice(ms) {
        var element = document.getElementById(ms);
        element.classList.add("activenotice");
    }

    function offNotice(ms) {
        var element = document.getElementById(ms);
        element.classList.remove("activenotice");
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function saveIdentity() {

        var ssnnumber = $('#ssnnumber').val();
        var dob = $('#dob').val();
        var phone = $('#phone').val();
        var luid = localStorage.getItem('uid')


        if (ssnnumber === '') {
            offTheBar('ssnnumber')
        } else if (dob === '') {
            offTheBar('dob')
        } else if (phone === '') {
            offTheBar('phone')
        } else {

            var obj = {
                ssnnumber: ssnnumber,
                dob: dob,
                phone: phone,
                LUID: luid

            }
            console.log('Step three values : = ' + obj)
            $.ajax({
                type: 'POST',
                url: 'https://app.liquidela.com/signup_stepthree.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result != 'fail') {
                        console.log(result);
                        var arr = result.split('|');
                        var username = arr[0];
                        var useremail = arr[1];
                        var userid = arr[2];
                        var userpass = arr[3];
                        var userphone = arr[4];
                        var efxusername = arr[5];
                        var cardnumber = arr[6];
                        var cardname = arr[7];
                        var nxtbilldt = arr[8];
                        localStorage.setItem('uid', userid);
                        localStorage.setItem('upass', userpass);
                        localStorage.setItem('uemail', useremail);
                        localStorage.setItem('userphone', userphone);
                        localStorage.setItem('uname', username);
                        localStorage.setItem('efxuserid', efxusername);
                        localStorage.setItem('crdnumber', cardnumber);
                        localStorage.setItem('crdname', cardname);
                        localStorage.setItem('nextbill', nxtbilldt);
                       
                      window.location.href = "https://app.liquidela.com/crsfunctions.php?LUID="+luid;
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }

    return (
        <div>
            <Toppart />
            <div class="container forms">



                <Topsigninbar />

                <div class="f-timeline">

                    <div class="step">
                        <div class="line-t"></div>
                        <div class="circle-num">
                            <div class="c1 c3color">1</div>
                            <div class="c2 c3color">2</div>
                            <div class="c3 c1color">3</div>
                        </div>
                    </div>

                    <div class="arrowed-opt">
                        <div class="ar-3">Create Your Account</div>
                        <div class="ar-3">Billing Information</div>
                        <div class="ar-1">Identity Information</div>
                    </div>

                </div>

                <div class="checkout-main">

                    <div class="checkout-1">

                        <div class="leftsection">

                            <div class="account-info">

                                <div class="content-box">

                                    <div class="group-title"><h4>Identity Information</h4></div>

                                    <span class="ssn-note">
                                        <p>Your social security number and DOB are used to confirm your identity. This info will be securely transmitted to the credit reporting agencies.</p>
                                    </span>

                                    <div class="input-form">
                                        <div class="card-details pos-rel">
                                            <label for="">Social Security Number <img src={Ntimg} onMouseOver={() => { shownotice('not23') }} onMouseOut={() => { offNotice('not23') }} /></label>
                                            <input type="text" id="ssnnumber" placeholder="SSN *" />
                                            <p className="noticebox" id="not23">We use your Social Security Number to confirm your identity. this won't
                                                affect your credit score.</p>
                                        </div>

                                        <div class="card-details pos-rel">
                                            <label for="">Date of Birth <img src={Ntimg} onMouseOver={() => { shownotice('not24') }} onMouseOut={() => { offNotice('not24') }} /></label>
                                            <input type="date" id="dob" placeholder="Number *" />
                                            <p className="noticebox" id="not24">We use your date of birth to confirm your identity. your information will be safe.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="account-info">

                                <div class="content-box">



                                    <div class="input-form">
                                        <div class="card-details pos-rel">
                                            <label for="">Enter Your Phone Number <img src={Ntimg} onMouseOver={() => { shownotice('not25') }} onMouseOut={() => { offNotice('not25') }} /></label>
                                            <input type="text" id="phone" placeholder="Phone Number *" />
                                            <p className="noticebox" id="not25">We need your phone to provide you with alerts and other notifications.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="account-info">

                                <div class="agreement">
                                    <div class="chk"><input type="checkbox" /></div>
                                    <p>Send me identity and credit monitoring alerts via SMS, if available.</p>
                                </div>



                            </div>


                            <div class="account-info">

                                <div class="content-box">

                                    <div class="input-form align-content">
                                        <button type="button" onClick={saveIdentity}>
                                            <span>Next</span>
                                            <span class="play-ic"><i class="ri-play-fill"></i></span>
                                        </button>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div class="rightsection">
                            <div class="order-summary">

                                <div class="heading"><h3>Order Summary</h3></div>

                                <div class="summary-line"></div>

                                <div class="trial-notice">
                                    <div class="txt-trail">
                                        <h4>Free Trial</h4>
                                    </div>

                                    <div class="amount">
                                        <p>Free for 30 days then</p>
                                        <p><h4>$6.99/mo</h4></p>
                                    </div>
                                </div>

                                <div class="features">
                                    <div class="txt-trail">
                                        <h4>Features</h4>
                                    </div>

                                    <div class="items">
                                        <div class="item">
                                            <div class="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Offers</p>
                                        </div>

                                        <div class="item">
                                            <div class="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Score</p>
                                        </div>

                                        <div class="item">
                                            <div class="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Credit Report</p>
                                        </div>

                                        <div class="item">
                                            <div class="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Identity Restoration</p>
                                        </div>

                                        <div class="item">
                                            <div class="item-ic"><img src={Checkimg} alt="" /></div>
                                            <p>Security</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="total-due">
                                    <div class="total-due-txt">
                                        <h3>Total Due Today</h3>
                                    </div>

                                    <div class="amount">
                                        <h3>$0.00</h3>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div class="privacy-section"><i class="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>


            </div>
            <Footer/>
        </div >
    )
}
