import React from 'react'
import Brandid from '../images/brand-id.png'


export default function Toppart() {
  return (
    <div>
      <div className="nav-dsk smallconteiner">
        <div className="brand">
            <a href=""><img src={Brandid} alt=""/></a>
        </div>
    </div>
    </div>
  )
}
