import React from 'react'
import Innerpage_topbar from './Innerpage_topbar';
import Footer from './Footer'
import Checkmark from '../images/checkmark-icon.png'


export default function Congratulation() {
    
  return (
    <div>
        <Innerpage_topbar />
         <div class="thankyou-ad">        
        </div>
    
        <div class="check-mark">
            <div class="check-icon">
                <img src={Checkmark} alt=""/>
            </div>
        </div>
    
        <div class="loan-process column">
            <h3>Loan Application is now in process</h3>
        </div>    
        <div class="agreement column">    
            <div class="loan-f-item">
                <span class="success-txt">                    
                <p>Congratulations for submitting your loan application with Premlo, Inc. through Liquidela.</p>                    
                <p>Premlo, Inc. is now processing your loan application of </p>    
                <p>A representative from Premlo, Inc. will be in touch with you for any additional information.</p>    
                <p>Please feel free to contact Liquidela for any further assistance at <span className="spanclass"><a href="tel:+18882620274">1 (888) 262 0274</a></span> or email us at <span className="spanclass">support@liquidela.com</span></p>    
                </span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
