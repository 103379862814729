import React from 'react'
import Brand_id from '../images/brand-id.png'

export default function Footer() {
  return (
    <div className='setatbottom'>
       <div className="footer-dsk">
            <div className="brand">
                <a href="index.html"><img src={Brand_id} alt=""/></a>
            </div>
    
    
            <div className="terms_privacy">
            <div className="privacy"><a href="https://app.termly.io/document/privacy-policy/95a109ce-0064-415c-92e2-7d9967e723a2
" target="_blank">Privacy Policy</a></div>
                <div className="terms"><a href="https://app.termly.io/document/terms-of-service/9423cd69-88eb-4fca-82be-1077f9e84199
                " target="_blank">Terms of Conditions</a></div>
                
                <div className="copyright">© Liquidela Inc. 2024</div>
            </div>
        </div>
    </div>
  )
}
