import React from 'react'
import Brand_id from '../images/brand-id.png';
import User from '../images/user.png';
import Notification from '../images/notification.png';
import { Link,useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function Innerpage_topbar() {
    const navigate = useNavigate();
    var menuind = 0;
    function openmenu(){
        if(menuind===0){
            $('#monmenu').css('display','block');
            menuind = 1;
        }else{
            menuind = 0;
            $('#monmenu').css('display','none');
        }
        
    }
    var ind = 0;
    function openDropDown(){ 
        if(ind==0){
            $('.dropdownplate').css('display','block')
            ind = 1;
        }else{
            $('.dropdownplate').css('display','none')
            ind = 0;
        }
    }

    function gotodefault(){
        navigate('/userdashboard');        
    }
    return (
        <div>
            <div className="dash-navbar row">

                <div className="navbar-left row">

                    <div className="brand-id" onClick={gotodefault}>
                        <img src={Brand_id} alt=""/>
                    </div>

                    <div className='collapsemenu' onClick={openmenu}>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </div>

                    <div className="nav-items deskview">
                    
                        <ul className="row">
                        <li><Link to="/userdashboard">Dashboard</Link></li>
                            <li><Link to="/identityrestoration">Identity Restoration</Link></li>
                            <li><Link to="/loanapplication">Get Loan</Link></li>
                            <li><Link to="/creditreport">Credit Report</Link></li>
                            <li><Link to="/creditscore">Credit Score</Link></li>
                        </ul>

                    </div>

                    <div className="nav-items mobview" id="monmenu">
                    
                        <ul className="row">
                        <li><Link to="/userdashboard">Dashboard</Link></li>
                            <li><Link to="/identityrestoration">Identity Restoration</Link></li>
                            <li><Link to="/loanapplication">Get Loan</Link></li>
                            <li><Link to="/creditreport">Credit Report</Link></li>
                            <li><Link to="/creditscore">Credit Score</Link></li>
                            <li><Link to="/userprofile">Profile</Link></li>
                            <li><Link to="/logout">Logout</Link></li>
                        </ul>

                    </div>

                </div>

                <div className="navbar-right deskview">

                    <div className="usr-actions row">
                    <div className="notification"><img src={Notification} alt=""/></div>
                        <div className="settings row pos-rel settinglink" >
                               <p className='fullwidth'> Profile & Settings</p> <i className="ri-arrow-down-s-line"></i>
                                <div className='dropdownplate'>
                                    <Link to="/userprofile">Profile</Link>
                                    <Link to="/logout">Logout</Link>
                                </div>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    )
}
