import React from 'react'
import Toppart from './Toppart'
import Checkone from '../images/check-1.png'
import $ from 'jquery';
import Topsigninbar from './Topsigninbar';
import Footer from './Footer';
import { useEffect } from 'react';


export default function Link_sent_confirm() {
    useEffect(()=>{
        var emailval = localStorage.getItem('uemail');
        var txt = 'Please check your inbox '+emailval
        $('#settext').text(txt);
    })
  return (
    <div>
       <Toppart />
       <div class="container">
       <Topsigninbar />
        <div class="payment-form align-item-center">
            <div class="password-reset-pop">
                <div class="pass-reset align-item-center">
                    <div class="heading column">
                        <img src={Checkone} alt=""/>
                        <h2>Password Link Sent</h2>
                        <p id="settext"></p>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="privacy-section"><i class="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>
    </div>
    <Footer/>
    </div>
  )
}
