import React from 'react'
import Toppart from './Toppart'
import Googleimg from '../images/gl.jpg'
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import Topsigninbar from './Topsigninbar';
import Footer from './Footer'

export default function Prevsignup() {
    const navigate = useNavigate();
    
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }
   
    function presign(){
        var useremail = $('#useremail').val();
        if(validateEmail(useremail)){
        localStorage.setItem('useremail',useremail);
        console.log(useremail)
        navigate('/signone');
        }else{
            alert('Please insert a valid email.')
        }

    }
    function gowithgoogle(){
        window.location.href='https://app.liquidela.com/google-oauth.php';
    }
    
    return (
        <div>
            <Toppart />
            <div className="container forms">

                <Topsigninbar />

                <div className="form login">

                    <div className="form-content">
                        <div className="header">
                            <h1>Create Your Account</h1>
                            <p className="signuptext">Free for <b>30</b> days, then its only $6.99</p>
                            <p className="signuptext">Cancel anytime</p>
                        </div>

                        

                            <div className="media-options">
                                <button className="field google googlebtn" onClick={gowithgoogle}>
                                    <img src={Googleimg} alt="" className="google-img" />
                                    <span>With Google</span>
                                </button>
                            </div>

                            <div className="line"></div>

                            <div className="sign-up-txt">
                                <input type="text" id="useremail" className="usr-txt-bx" placeholder="name@domain.com"/>
                                    <button onClick={presign}>Continue</button>
                            </div>

                            <div className="field input-field">
                                <p>By signing up I agree to the Liquidela Inc.'s <a href="">Privacy Policy</a> and <a href="#">Terms of Service.</a></p>
                            </div>

                        



                    </div>

                </div>

                <div className="privacy-section"><i className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>
        </div>
    )
}
