import React from 'react'
import { Link } from 'react-router-dom'

export default function Topbarsignup() {
  function offTheBar(ms){
    var element = document.getElementById(ms);
      element.classList.add("deactivetopbar");
}
  return (
    <div>
      <div className="pop-up" id="topbar"><span>Don’t have an account? <span className="bold whitelink"><Link to="/signup">Sign Up</Link></span></span> <i className="ri-close-fill close-btn-root" onClick={()=>{offTheBar('topbar')}}></i></div>
    </div>
  )
}
