import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Component/Login';
import Prevsignup from './Component/Prevsignup';
import Signup_stepone from './Component/Signup_stepone';
import Signup_steptwo from './Component/Signup_steptwo';
import Signup_stepthree from './Component/Signup_stepthree';
import User_dashboard from './Component/User_dashboard';
import Resetpass from './Component/Resetpass';
import Loan_Applicaation from './Component/Loan_Applicaation';
import Creditscore from './Component/Creditscore';
import Logout from './Component/Logout';
import Signup from './Component/Signup';
import User_details_form from './Component/User_details_form';
import Link_sent_confirm from './Component/Link_sent_confirm';
import User_profile from './Component/User_profile';
import Identity_information from './Component/Identity_information';
import Identity_restoration from './Component/Identity_restoration';
import Creditreport from './Component/Creditreport';
import Account_settings from './Component/Account_settings';
import Congratulation from './Component/Congratulation';


function App() {
  return (
    <section className='singleheight'>
    <Router>     
    <div className='fullwidth'>         
      <Routes>       
        <Route exact path="/" element={<Login/>} />
        <Route exact path="/prevsignup" element={<Prevsignup/>} />
        <Route exact path="/signone" element={<Signup_stepone/>} />
        <Route exact path="/signtwo" element={<Signup_steptwo/>} />
        <Route exact path="/signthree" element={<Signup_stepthree/>} />
        <Route exact path="/userdashboard" element={<User_dashboard/>} />
        <Route exact path="/resetpassword" element={<Resetpass/>} />
        <Route exact path="/loanapplication" element={<Loan_Applicaation/>} />
        <Route exact path="/creditreport" element={<Creditscore/>} />
        <Route exact path="/logout" element={<Logout/>} />
        <Route exact path="/signup" element={<Signup/>} />
        <Route exact path="/userdetailsform" element={<User_details_form/>} />
        <Route exact path="/passwordresetlinksent" element={<Link_sent_confirm/>} />
        <Route exact path="/userprofile" element={<User_profile/>} />
        <Route exact path="/identityinfo" element={<Identity_information/>} />
        <Route exact path="/identityrestoration" element={<Identity_restoration/>} />
        <Route exact path="/creditscore" element={<Creditreport/>} />
        <Route exact path="/accountsettings" element={<Account_settings/>} />
        <Route exact path="/congratulation" element={<Congratulation/>} />
        


      </Routes>  

    </div>
    </Router>
  </section>
  );
}

export default App;
